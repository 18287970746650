export const federalcasesdata = {
    traffTypes: [ 
        { 
            variable: "minorsextraf",
            name: "Minor Sex Trafficking",
            color: "#1957CD"
        }, 
        {
            variable: "adultsextraf" ,
            name: "Adult Sex Trafficking",
            color: "#0F347B"
        },  
        { 
            variable: "labor" ,
            name: "Labor Trafficking",
            color: "#46ADE3"
        },
        { 
            variable: "both" ,
            name: "Both Sex and Labor Trafficking",
            color: "#1895A7"
        },  
    ],
    raceEthnicity: [
        {
            variable: "black",
            name: "Black",
            color: "#1957CD"
        },
        {
            variable: "hispanic",
            name: "Hispanic",
            color: "#0F347B"
        },
        {
            variable: "other",
            name: "Other",
            color: "#46ADE3"
        },
        {
            variable: "unknown",
            name: "Unknown",
            color: "#1895A7"
        },
        {
            variable: "white",
            name: "White",
            color: "#A5E3ED"
        },
    ], 
    laborTraffType: [
        {
            variable: "Agriculture",
            name: "Agriculture",
            color: "#1957CD",
        },
        {
            variable: "Arts/Sports/Entertainment",
            name: "Arts/Sports/Entertainment",
            color: "#0F347B"
        },
        {
            variable: "Carnivals",
            name: "Carnivals",
            color: "#46ADE3"
        },
        {
            variable: "Commercial Cleaning Services",
            name: "Commercial Cleaning Services",
            color: "#1895A7",
        },
        {
            variable: "Construction",
            name: "Construction",
            color: "#A5E3ED"
        },
        {
            variable: "Domestic Servitude",
            name: "Domestic Servitude",
            color: "#44CEA5"
        },
        {
            variable: "Factories/Manufacturing",
            name: "Factories/Manufacturing",
            color: "#297C63"
        },
        {
            variable: "Forestry and Logging",
            name: "Forestry and Logging",
            color: "#AEE0C6"
        },
        {
            variable: "Healthcare",
            name: "Healthcare",
            color: "#532C95"
        },
        {
            variable: "Health and Beauty",
            name: "Health and Beauty",
            color: "#9E9BF0",

        },
        {
            variable: "Hotel and Hospitality",
            name: "Hotel and Hospitality",
            color: "#6F0C4E"
        },
        {
            variable: "Illicit Activities",
            name: "Illicit Activities",
            color: "#B2127C"
        },
        {
            variable: "Landscaping",
            name: "Landscaping",
            color: "#E1AFEF",
        },
        {
            variable: "Peddling and Begging",
            name: "Peddling and Begging",
            color: "#EC3240"
        },
        {
            variable: "Recreational Facilities",
            name: "Recreational Facilities",
            color: "#262F35"
        },
        {
            variable: "Restaurant and Food Services",
            name: "Restaurant and Food Services",
            color: "#A8ACAE"
        },
        {
            variable: "Traveling Sales Crew",
            name: "Traveling Sales Crew",
            color: "#1957CD"
        },
        {
            variable: "Other",
            name: "Other",
            color: "#0F347B"
        },
        {
            variable: "Unknown",
            name: "Unknown",
            color: "#46ADE3",
        },
    ],
    defendantsAge1: [ 
        {
            variable: "24 and Under" ,
            name: "24 and Under",
            color: "#1957CD"
        },  
        { 
            variable : "25-29" ,
            name: "25-29",
            color: "#0F347B"
        },
        { 
            variable : "30-39" ,
            name: "30-39",
            color: "#46ADE3"
        },  
        {
            variable : "40-49" ,
            name: "40-49",
            color: "#1895A7"
        },  {
            variable : "50 and Over" ,
            name: "50 and Over",
            color: "#A5E3ED"
        }
    ], 
    defendantGender: [ 
        { 
            variable : "female" ,
            name: "Female",
            color: "#1957CD"
        },
        { 
            variable : "male",
            name: "Male",
            color: "#0F347B"
        }, 
        {
            variable : "unknown" ,
            name: "Unknown",
            color: "#46ADE3"
        }, 
    ], 
    defendantCountry: [ 
        { 
            variable : "total" ,
            name: "Total Individuals",
            color: "#1957CD"
        },
    ],
    victimAge: [ 
        { 
            variable : "Adult" ,
            name: "Adult",
            color: "#1957CD"
        },
        { 
            variable : "Minor",
            name: "Minor",
            color: "#0F347B"
        }, 
        // {
        //     variable : "Unknown" ,
        //     name: "Unknown",
        //     color: "#46ADE3"
        // }, 
    ], 
    victimGender: [ 
        { 
            variable : "Female" ,
            name: "Female",
            color: "#1957CD"
        },
        { 
            variable : "Male",
            name: "Male",
            color: "#0F347B"
        }, 
    ],
}