export const statepolicydata = {
    policies: [ 
        { 
            variable: "prevention",
            name: "Prevention Policies",
            color: "#4E79A7"
        }, 
        {
            variable: "protection" ,
            name: "Protection Policies",
            color: "#A0CBE8"
        },  
        { 
            variable: "prosecution" ,
            name: "Prosecution Policies",
            color: "#F28E2B"
        },
    ],
    sentencingRequirements: [
        {
            variable: 'U.S.',
            name: 'U.S.',
            color: "#4E79A7"
        },
        {
            variable: 'AL',
            name: 'AL',
            color: "#F28E2B"
        },
        {
            variable: 'AK',
            name: 'AK',
            color: "#E15759"
        },
        {
            variable: 'AZ',
            name: 'AZ',
            color: "#76B7B2"
        },
        {
            variable: 'AR',
            name: 'AR',
            color: "#59A14E"
        },
        {
            variable: 'CA',
            name: 'CA',
            color: "#EDC948"
        },
        {
            variable: 'CO',
            name: 'CO',
            color: "#B07AA1"
        },
        {
            variable: 'CT',
            name: 'CT',
            color: "#FF9DA7"
        },
        {
            variable: 'DE',
            name: 'DE',
            color: "#9C755F"
        },
        {
            variable: 'FL',
            name: 'FL',
            color: "#F28E2B"
        },
        {
            variable: "GA",
            name: "GA",
            color: "#E15759"
        },
        {
            variable: "HI",
            name: "HI",
            color: "#76B7B2"
        },
        {
            variable: "ID",
            name: "ID",
            color: "#59A14E"
        },
        {
            variable: "IL",
            name: "IL",
            color: "#EDC948"
        },
        {
            variable: "IN",
            name: "IN",
            color: "#B07AA1"
        },
        {
            variable: "IA",
            name: "IA",
            color: "#FF9DA7"
        },
        {
            variable: "KS",
            name: "KS",
            color: "#9C755F"
        },
        {
            variable: "KY",
            name: "KY",
            color: "#F28E2B"
        },
        {
            variable: "LA",
            name: "LA",
            color: "#E15759"
        },
        {
            variable: "ME",
            name: "ME",
            color: "#76B7B2"
        },
        {
            variable: "MD",
            name: "MD",
            color: "#59A14E"
        },
        {
            variable: "MA",
            name: "MA",
            color: "#EDC948"
        },
        {
            variable: "MI",
            name: "MI",
            color: "#B07AA1"
        },
        {
            variable: "MN",
            name: "MN",
            color: "#FF9DA7"
        },
        {
            variable: "MS",
            name: "MS",
            color: "#9C755F"
        },
        {
            variable: 'MO',
            name: 'MO',
            color: "#F28E2B"
        },
        {
            variable: 'MT',
            name: 'MT',
            color: "#E15759"
        },
        {
            variable: 'NE',
            name: 'NE',
            color: "#76B7B2"
        },
        {
            variable: "NV",
            name: "NV",
            color: "#59A14E"
        },
        {
            variable: "NH",
            name: "NH",
            color: "#EDC948"
        },
        {
            variable: "NJ",
            name: "NJ",
            color: "#B07AA1"
        },
        {
            variable: "NM",
            name: "NM",
            color: "#FF9DA7"
        },
        {
            variable: "NY",
            name: "NY",
            color: "#9C755F"
        },
        {
            variable: "NC",
            name: "NC",
            color: "#F28E2B"
        },
        {
            variable: "ND",
            name: "ND",
            color: "#E15759"
        },
        {
            variable: "OH",
            name: "OH",
            color: "#76B7B2"
        },
        {
            variable: "OK",
            name: "OK",
            color: "#59A14E"
        },
        {
            variable: "OR",
            name: "OR",
            color: "#EDC948"
        },
        {
            variable: "PA",
            name: "PA",
            color: "#B07AA1"
        },
        {
            variable: "RI",
            name: "RI",
            color: "#FF9DA7"
        },
        {
            variable: "SC",
            name: "SC",
            color: "#9C755F"
        },
        {
            variable: "SD",
            name: "SD",
            color: "#F28E2B"
        },
        {
            variable: "TN",
            name: "TN",
            color: "#E15759"
        },
        {
            variable: "TX",
            name: "TX",
            color: "#76B7B2"
        },
        {
            variable: "UT",
            name: "UT",
            color: "#59A14E"
        },
        {
            variable: "VT",
            name: "VT",
            color: "#EDC948"
        },
        {
            variable: "VA",
            name: "VA",
            color: "#B07AA1"
        },
        {
            variable: "WA",
            name: "WA",
            color: "#FF9DA7"
        },
        {
            variable: "WV",
            name: "WV",
            color: "#9C755F"
        },
        {
            variable: "WI",
            name: "WI",
            color: "#F28E2B"
        },
        {
            variable: "WY",
            name: "WY",
            color: "#E15759"
        },
    ]
}