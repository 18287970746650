import React, { useEffect } from "react";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function PublicSafetyHorizontalTable(props) {
  const classes = useStyles();
  const [customData, setCustomData] = React.useState([]);
  const [variableName, setVariableName] = React.useState("");

  const [total, setTotal] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [displayValue, setDisplayValue] = React.useState("Total");


  useEffect(() => {
      setVariableName(Object.keys(props.data[0])[0])
      if (props.reverseOrder) {
        var newArray = [...props.data];
        var concatArray = (JSON.parse(JSON.stringify(newArray)))
        var sortedArray = concatArray.sort((a,b) => {
          return b.total ? b.total - a.total : b.value - a.value
        })
        setCustomData(sortedArray)
      } else {
        setCustomData(props.data)
      }
      
      if (props.pie) {
        let allTotal = d3.sum(
          props.data.map((d) => {
            return d.value;
          })
        );
        setTotal(allTotal)  
      } else {
        let allTotal = d3.sum(
          props.data.map((d) => {
            return d.total ? d.total : d.value;
          })
        );
        setTotal(allTotal) 
      }
       
      
  }, [props.data, props.id, props.pie, props.reverseOrder])

  return (
    <TableContainer component={Paper} id={`${props.id}`}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={`${props.fcdDashboard ? 'fontWeight700' : 'fontWeight600'}`}>
            <TableCell>
                {props.name ?? props.id}  
            </TableCell>
            <TableCell align="right">
                Value  
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {customData.map((item, key) => {
             return (
                <TableRow key={key}>
                    <TableCell>
                          {item[variableName]}  
                    </TableCell>
                    <TableCell align="right">{props.pie ? item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : item.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                </TableRow>
             )
         })}
          <TableRow className={`${props.fcdDashboard ? 'fontWeight700' : 'fontWeight600'}`}>
            <TableCell>
                  {displayValue}  
            </TableCell >
            <TableCell align="right">{displayValue === "Average" ? total : total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
