import React, { useEffect } from "react";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function PeriodOfExploitationTable(props) {
  const classes = useStyles();
  const [customData, setCustomData] = React.useState([]);
  const [total, setTotal] = React.useState(0);


  useEffect(() => {
    setCustomData(props.data)

    let allTotal = d3.sum(
      props.data.map((d) => {
        return d.total;
      })
    );
    setTotal(allTotal)
  
    
  }, [props.keys, props.data, props.single, props.id])

  return (
    <TableContainer component={Paper} id={`${props.id}`}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={`${props.fcdDashboard ? 'fontWeight700' : 'fontWeight600'}`}>
            {props.headers?.map((h, hIndex) => (
              <TableCell align={hIndex === 0 ? '' : 'center'}>{h}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {customData.map((item, key) => (
            <React.Fragment key={key}>
              {props.keys.map((keyObj, keyB) => {
                return (
                  <TableRow key={`${keyObj.name}${key}${keyB}`}>
                    <TableCell>{keyObj.name}</TableCell>
                    <TableCell align="center">{item.xaxis}</TableCell>
                    <TableCell align="center">{item[keyObj.variable]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}</TableCell>
                  </TableRow>
                )
              })}
            </React.Fragment>
          ))}
          <TableRow className={`${props.fcdDashboard ? 'fontWeight700' : 'fontWeight600'}`}>
            <TableCell>Total</TableCell>
            <TableCell></TableCell>
            <TableCell align="center">{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
