import React, { Fragment, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';
// import { renderToString } from "react-dom/server";
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import ListAltIcon from '@material-ui/icons/ListAlt';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    // color: theme.palette.grey[500],
  },
  graphParent: {
    width: "78vw !important",
    overflow: "scroll !important",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function SingleCard(props) {
  const [open, setOpen] = React.useState(false);
  const [definitions, setDefinitions] = React.useState([]);
  const [openDefinition, setDefinitionOpen] = React.useState(false);
  const [activeClassName, setActiveClassName] = React.useState("single-card");
  const [showingChartLine, setShowingChartLine] = React.useState(props.graph.props.percentLine ? props.graph.props.percentLine : props.graph.props.trendLine)
  const [showingChartLineTwo, setShowingChartLineTwo] = React.useState(props.graph.props.trendLineTwo ?? false)
  const [hasActiveCategory, setHasActiveCategory] = React.useState(props.graph.props.activeCategory)
  // console.log('props.graph: ', props.graph)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDefinitionOpen= () => {
    setDefinitionOpen(true);
  };

  const handleDefinitionClose = () => {
    setDefinitionOpen(false);
  };

  const addToReport = (title) => {
    props.addToReports(title, showingChartLine, showingChartLineTwo, hasActiveCategory);
  };

  useEffect(() => {
    if(props.definitions){
      setDefinitions(props.definitions);
    } else {
      setDefinitions([]);
    }
    setActiveClassName(`single-card ${props.title.split("  >")[0]}`)
  }, [props.definitions, props.title]);


  useEffect(() => {
    if (props.graph.props.activeCategory) {
      setHasActiveCategory(props.graph.props.activeCategory)
    }
  }, [props.graph.props?.activeCategory])


  return (
    <div style={{ padding: "10px 0px" }} id={props.id} className={activeClassName}>
      <Card
        className={`singleCard ${props.fcdDashboard && 'fcd'}`}
        style={{ width: props.width }}>
        <CardHeader
          title={props.title}
          action={
            <div>
              {" "}
                <Tooltip title="Table Data" placement="top">
                  <IconButton onClick={handleClickOpen} aria-label="show more">
                    <ListAltIcon />
                  </IconButton>
                </Tooltip>
               <Tooltip title="Definitions" placement="top">
                <IconButton onClick={handleDefinitionOpen} aria-label="show more">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add To Reports" placement="top">
                <IconButton
                  aria-label="add to reports"
                  onClick={(e) => addToReport(props.title)}
                >
                  <AddToQueueIcon />
                </IconButton>
              </Tooltip>

            </div>
          }
        />{" "}
        <CardContent>
          {props?.underTitleContent}

          { props.description
            ? <Typography>{props.description}</Typography>
            : <Fragment></Fragment>
          }
          <Typography variant="body2" color="textSecondary" component="span">
            {props.type === "text" ? (
              <div>{props.graph}</div>
            ) : (
              <div
                style={{
                  //  overflowX: "scroll",
                  overflow: "auto",
                }}
              >
                  {React.cloneElement(props.graph, { chartLineShowing: showingChartLine, updateChartLineShowing: setShowingChartLine, updateChartLineShowingTwo: setShowingChartLineTwo, activeCategory: hasActiveCategory, updateActiveCategory: setHasActiveCategory})}
              </div>
            )}
          </Typography>
        </CardContent>
      </Card>
      <div>
        <Dialog
          className={`chartTableDialog ${props.shouldUseNewStyles && "public-site-dialog"} ${props.fcdDashboard && 'fcd'}`}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"lg"}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} className={`${props.fcdDashboard && 'fontWeight700'}`}>
            {props.title}
          </DialogTitle>
          <DialogContent>
            {/* {props.graph} */}
            {props.table}
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary" className={`${props.fcdDashboard && 'fontWeight700'}`}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          onClose={handleDefinitionClose}
          className={`chartInfoDialog ${props.shouldUseNewStyles && "public-site-dialog"} ${props.fcdDashboard && 'fcd'}`}
          aria-labelledby="customized-dialog-title"
          open={openDefinition}
          maxWidth={"lg"}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleDefinitionClose}
            className={`${props.fcdDashboard && 'fontWeight700'}`}
          >
            {props.title}
          </DialogTitle>
          <DialogContent>
            {props.title === "Individuals in Care Coordination" ? (
              <div>
                <h4 className={`${props.fcdDashboard ? 'fontWeight700' : 'fontWeight500'}`}>Individuals newly admitted into CC</h4>
              <p className="dialog-text">Number of individuals newly admitted into CC -- count individual only once in same reporting year, even if individual exits and returns</p>
                <h4 className={`${props.fcdDashboard ? 'fontWeight700' : 'fontWeight500'}`}>Number of individuals participating in active CC </h4>
              <p className="dialog-text">Active care coordination - defined by the local CCT but at a minimum, individuals that are missing from placement or other planned location may be considered continuing in Active CC for up to 30 days if they remain in virtual or other contact with the advocate during that time missing. <b>Count individuals in active CC on the last day of the reporting period.</b> Re-count any individuals participating in active CC each quarter. Missing individuals with no contact with CC or CSEY advocate for 30 days should be discharged from active CC.</p>
            </div>
            ) :
            <div>
              {definitions.map((item,key) => {
                return (
                  <div key={key}>
                    <h4 className={`${props.fcdDashboard ? 'fontWeight700' : 'fontWeight500'}`}>{item[0]}</h4>
                    {typeof item[1] === 'string' ?
                      <p className="dialog-text" dangerouslySetInnerHTML={{ __html: item[1] }}></p>
                      :
                      <div className="dialog-text">{item[1]}</div>
                    }
                  </div>
                )
              })}
            </div> }
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDefinitionClose} color="primary" className={`${props.fcdDashboard && 'fontWeight700'}`}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
