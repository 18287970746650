import React, { createRef, useEffect, useState } from "react";
import * as d3 from "d3";

const PieGraph = (props) => {
  const chartRef = createRef();
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, []);

  useEffect(() => {
    if (ready) {
      var width = 800;
      var height = 350;
      if (props.fcdDashboard) {
        height = 400
      } 

      let dataset;
      if (props.keys) {
        dataset = props.data.sort((a, b) =>
          props.keys.indexOf(props.keys.filter(k => k.variable === a[props.sourceNameVar ?? 'label'])[0]) -
          props.keys.indexOf(props.keys.filter(k => k.variable === b[props.sourceNameVar ?? 'label'])[0])
        )
        console.log('dataset ', dataset)
      } else {
        dataset = props.data;
      }

      if (props.value === "Long Term Residential") {
        for (let i = 0; i < dataset.length; i++) {
          // eslint-disable-next-line no-unused-vars
          for (let key in dataset[i]) {
            if (dataset[i].source === "impact_school") {
              dataset[i].label = "Enrolled in School/Employed";
            } else if (dataset[i].source === "impact_safe") {
              dataset[i].label = "Living in Safe/Stable Environment";
            } else if (dataset[i].source === "impact_discharge_plans") {
              dataset[i].label = "Following through with discharge plans";
            } else if (dataset[i].source === "impact_contact") {
              dataset[i].label = "Maintaining contact with support system";
            } else if (dataset[i].source === "impact_unknown") {
              dataset[i].label = "Unknown";
            }
          }
        }
      }

      var radius = width / 5;

      var legendRectSize = 25;
      var legendSpacing = 6;

      var color;
      if (props.keys) {
        color = d3.scaleOrdinal(props.keys.map(key => {
          return key.color
        }));
      } else {
        color = props?.colors ? 
          d3.scaleOrdinal(props.colors)
        :
          d3.scaleOrdinal([
          "#4E79A7",
          "#F28E2B",
          "#E15759",
          "#76B7B2",
          "#59A14E",
          "#EDC949",
          "#B07AA2",
          "#FF9DA7",
          "#9C755F"
        ]);

      }

      let svg = d3.select(chartRef.current);
      svg.selectAll("svg").remove();

      var piesvg = svg
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 5 + "," + 250 + ")");

      var arc = d3.arc().innerRadius(0).outerRadius(radius);

      var pie = d3
        .pie()
        .value(function (d) {
          return d.value;
        })
        .sort(null);

      // define tooltip
      var tooltip = d3
        .select(chartRef.current)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px");

      tooltip.append("div").attr("class", "label");

      tooltip.append("div").attr("class", "count");

      tooltip.append("div").attr("class", "percent");

      dataset.forEach(function (d) {
        d.value = +d.value;
        d.enabled = true;
      });

      // creating the chart
      var path = piesvg
        .selectAll("path")
        .data(pie(dataset))
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("transform", "translate(20,-70)")
        .attr("fill", function (d) {
          return color(d.data[props.sourceNameVar ?? 'label']);
        })
        // .each((d) => this._current = d);

      // mouse event handlers are attached to path so they need to come after its definition
      path.on("mouseover", function (d) {
        var total = d3.sum(
          dataset.map(function (d) {
            return d.enabled ? d.value : 0;
          })
        );
        var percent = Math.round((1000 * d.data.value) / total) / 10;
        tooltip.select(".label").html(d.data[props.sourceNameVar ?? 'label']);
        tooltip.select(".count").html("Total: " + d.data.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        tooltip.select(".percent").html("Percent: " + percent + "%");
        tooltip.style("opacity", 1);
        tooltip.style("z-index", "9999")

      });

      path.on("mouseout", function () {
        tooltip.style("opacity", 0);
        tooltip.style("z-index", "-1")

      });

      path.on("mousemove", function (d) {
        tooltip
          .style("top", (d3.event.clientY - 200) + "px")
          .style("left", (d3.event.clientX - 265) + "px");
      });

      var legend = piesvg
        .selectAll(".legend")
        .data(color.domain())
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function (d, i) {
          var height = legendRectSize + legendSpacing;
          var vert = i * height - 150;
          return "translate(" + 250 + "," + vert + ")";
        });

      // adding colored squares to legend
      legend
        .append("rect") // append rectangle squares to legend
        .attr("width", legendRectSize) // width of rect size is defined above
        .attr("height", legendRectSize) // height of rect size is defined above
        .style("fill", color) // each fill is passed a color
        .style("stroke", color) // each stroke is passed a color
        .on("click", function (label) {
          var rect = d3.select(this); // this refers to the colored squared just clicked
          var enabled = true; // set enabled true to default
          var totalEnabled = d3.sum(
            dataset.map(function (d) {
              // can't disable all options
              return d.enabled ? 1 : 0; // return 1 for each enabled entry. and summing it up
            })
          );

          if (rect.attr("class") === "disabled") {
            // if class is disabled
            rect.attr("class", ""); // remove class disabled
          } else {
            // else
            if (totalEnabled < 2) return; // if less than two labels are flagged, exit
            rect.attr("class", "disabled"); // otherwise flag the square disabled
            enabled = false; // set enabled to false
          }

          pie.value(function (d) {
            if (d.label === label) d.enabled = enabled; // if entry label matches legend label
            return d.enabled ? d.value : 0; // update enabled property and return count or 0 based on the entry's status
          });

          path = path.data(pie(dataset)); // update pie with new data

          path
            .transition() // transition of redrawn pie
            .duration(750) //
            .attrTween("d", function (d) {
              // 'd' specifies the d attribute that we'll be animating
              var interpolate = d3.interpolate(0, d); // this = current path element
              // interpolate(0, d); // interpolate between current value and the new value of 'd'
              return function (t) {
                return arc(interpolate(t));
              };
            });
        });

      // adding text to legend
      legend
        .append("text")
        .attr('class', 'piechartLegend')
        .attr("x", legendRectSize + legendSpacing)
        .attr("y", legendRectSize - legendSpacing)
        .text(function (d) {
          return d;
        }); // return label
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, ready]);

    return (
      <div className={`chart ${props.fcdDashboard && 'fcd'}`} id={props.id} ref={chartRef}></div>
    );
}
export default PieGraph;
