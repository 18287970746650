// import { parishData as parishJson } from '../Data/parishJson'
// import { laDistrictJson } from "../Data/laDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'

export const mapsLAData = {
    mapZoom: 5,
    reportMapZoom: 4,
    mapBoxCenter: [-92.329102, 30.39183],
    mapBoxCenterReport: [-92.329102, 30.39183],
    mapMaxBounds: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
    mapMaxBoundsReport: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
    reportMapWidth: '577px',

    'Federal Cases': {
        mapZoom: 4,
        reportMapZoom: 3,
        mapBoxCenter: [-98.5795, 39.8282],
        mapBoxCenterReport: [-98.5795, 39.8282],
        mapMaxBounds: '',
        mapMaxBoundsReport: '',
        reportMapWidth: '100%',

        'Location ': mapsNationalData['Federal Cases']['Location '],
        mapInfo: mapsNationalData['Federal Cases'].mapInfo,

    //         'State Prosecuted': mapsNationalData['Federal Cases']['Location ']['State Prosecuted'],

    //         'District Prosecuted': {
    //             dataVar: 'districtsTotals',
    //             sourceVar: 'district_prosecuted',
    //             totalDataVar: '',
    //             mapJson: JSON.parse(JSON.stringify(laDistrictJson)),
    //             mapLocVar: 'district',
    //             mapFillOpacity: 0,
    //             mapFillColors: [
    //                 [1, "#e9f6fc"],
    //                 [5, "#93d6f1"],
    //                 [10, "#28ade3"],
    //                 [15, "#186788"],
    //                 [20, "#0c3344"],
    //                 [25, "#000000"],
    //             ],
    //         },

    //         'Trafficking Locations': {
    //             dataVar: 'counties',
    //             sourceVar: 'crime_location_county',
    //             totalDataVar: 'parishesTotal',
    //             mapJson: JSON.parse(JSON.stringify(parishJson)),
    //             mapLocVar: 'COUNTY',
    //             mapFillOpacity: 0.9,
    //             mapFillColors: [
    //                 [1, "#e9f6fc"],
    //                 [20, "#93d6f1"],
    //                 [40, "#28ade3"],
    //                 [60, "#186788"],
    //                 [80, "#0c3344"],
    //                 [100, "#000000"],
    //             ],
    //         },
    //     },
    //     mapInfo:
    //         <div>
    //             <table className="data-table">
    //             <tr>
    //                 <th>Data Source</th>
    //                 <th>Date</th>
    //             </tr>
    //             <tr>
    //                 <td>Human Trafficking Data</td>
    //                 <td>2000-current</td>
    //             </tr>
    //             </table>
    //             <p>
    //             Federal Case data is from Dr. Vanessa Bouché. Data is based on federal cases prosecuted in or involving the viewed state, and includes information about trafficking locations, demographics, and crime methods.
    //             <br /><br />
    //             The Trafficking Locations filter refers to specific businesses, addresses, cities and counties/parishes where victims were recruited, transported, obtained, retained, harbored and exploited. This view does not include all cases, since some case documents do not specify where in the state trafficking occurred. Those cases are coded with "Unlisted" trafficking locations.
    //             <br /><br />
    //             The District Prosecuted filter is the district in which the case was prosecuted. If a case has been transferred to another district, the case falls under the district where sentencing occurred. This view includes only cases prosecuted in the state.
    //           </p>
    //         </div>,
    },
    'State Policy': {
      mapZoom: 4,
      reportMapZoom: 3,
      mapBoxCenter: [-98.5795, 39.8282],
      mapBoxCenterReport: [-98.5795, 39.8282],
      mapMaxBounds: '',
      mapMaxBoundsReport: '',
      reportMapWidth: '100%',
  
      'Data': mapsNationalData['State Policy']['Data'],
      mapInfo: mapsNationalData['State Policy'].mapInfo
    }
}