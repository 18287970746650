import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
//import { orgList } from "../../../Data/orgList";
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        fontWeight: '300',
        overflowY: 'scroll'
    },
    filterWrapper: {
      display: 'flex'
    },
    active: {
      cursor: 'pointer'
    },
    inactive: {
      fontWeight: '400 !important',
      cursor: 'pointer'
    },
    checkboxLabel: {
      padding: '0px'
    },
    checkboxes: {
      marginLeft: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    saveButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    }
}));

export default function HotlineSituationsFilter(props) {
  const classes = useStyles();
  const [filterSelections, setFilterSelections] = React.useState([]);

  const handleCheckboxChange = (e, item) => {
    let filterItems = [...filterSelections];
    if (filterItems.includes(item)){
      const index = filterItems.indexOf(item);
      if (index > -1) {
        filterItems.splice(index, 1); // 2nd parameter means remove one item only
      }
      setFilterSelections(filterItems);
    } else {
      filterItems.push(item)
      setFilterSelections(filterItems);
    }
  }

  const handleSubmit = () => {
    props.filterCallback({
      name: 'Cases',
      filter: 'Hotline',
      selection: filterSelections,
      value: filterSelections
    })
    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++){
      if (props.selectedFilters[i].name === 'Cases' && props.selectedFilters[i].filter === 'Hotline'){
        setFilterSelections(props.selectedFilters[i].value)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return (
        <div className={classes.container}>
          <div className={classes.checkboxes}>
          {/* <FormControlLabel className="checkboxLabel"
              control={ 
                <BlackCheckbox 
                  checked={filterSelections.includes("Total")} 
                  onChange={e => handleCheckboxChange(e, "Total")} name="checkedA" />
                }
              label={"Total"}
            /> */}
            <FormControlLabel className="checkboxLabel"
              control={ 
                <BlackCheckbox 
                  checked={filterSelections.includes("Involves Minors")} 
                  onChange={e => handleCheckboxChange(e, "Involves Minors")} name="checkedA" />
                }
              label={"Involves Minors"}
            />
            <FormControlLabel className="checkboxLabel"
              control={ 
                <BlackCheckbox 
                  checked={filterSelections.includes("Involves Adults")} 
                  onChange={e => handleCheckboxChange(e, "Involves Adults")} name="checkedA" />
                }
              label={"Involves Adults"}
            />
        </div>
       
        <div className={classes.saveButton}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    )
}