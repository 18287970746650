export const chartData = [
    {
        name: "Services",
        state_location: "LA",
        charts: [{
            name: "Organizations",
            values: ["Total Organizations"]
        }, {
            name: "Client Information",
            values: ["Individuals Served", "Victim Status", "Type of Trafficking"]
        },{
            name: "Demographics",
                values: ["Age", "Type of Trafficking by Age", "Gender", "Race", "Ethnicity", "Sexual Orientation", "Education Level", "Disability", "Immigration Status", "Type of Trafficking by Gender", "Type of Trafficking by Race"]
        },{
            name: "Services Provided",
            values: ["Type of Service", "Length of Service", "Reason for Exit"]
            },
            {
                name: "National Referral Directory",
                values: ["Organizations"]
            }
        ]
    },
    {
        name: "Services",
        state_location: "TX",
        charts: [
        //     {
        //     name: "Bringing Justice",
        //     values: ["Total Organizations", "Governor's Office Funding"]
        // },
        {
            name: "CSEY Advocacy",
            values: ["Total Organizations", "Individuals Served", "New Individuals Served (by Age)", "Referral Sources", "Referrals Over Time", "Exiting Services by Reason", "Responses to Crisis Recoveries"]
        },{
            name: "Drop-In Center",
            values: ["Total Organizations", "Individuals Served", "New Individuals Served (by Age)", "Referral Sources", "Referrals Over Time", "Connecting with Individuals", "Connecting and Total Served", "Extended Safety", "Extended Safety and Total Served"]
        },{
            name: "Emergency Shelter",
            values: ["Total Organizations",  "Individuals Served", "New Individuals Served (by Age)", "Referral Sources", "Referrals Over Time", "Admissions by Referral Source", "Admissions by Referral Source (over time)", "Total Admissions and Referrals", "Average Length of Stay", "Average Length of Stay by Program", "Planned/Unplanned Discharges by Type"]
        },
        // {
        //     name: "Innovative Services",
        //     values: ["Total Organizations", "Governor's Office Funding"]
        // },
        {
            name: "Long Term Residential",
            values: ["Total Organizations", "Individuals Served", "New Individuals Served (by Age)", "Referral Sources", "Referrals Over Time", "Admissions by Referral Source", "Admissions by Referral Source (over time)", "Total Admissions and Referrals", "Average Length of Stay", "Average Length of Stay by Program", "Planned/Unplanned Discharges by Type", "Impact Assessment", "Impact Assessment (over time)"]
        }
        ,{
            name: "National Referral Directory",
            values: ["Organizations"]
        }
        // {
        //     name: "Prevention",
        //     values: ["Total Organizations", "Governor's Office Funding"]
        // },{
        //     name: "Therapy/Counseling",
        //     values: ["Total Organizations", "Governor's Office Funding"]
        // },{
        //     name: "Other",
        //     values: ["Total Organizations", "Governor's Office Funding"]
        // }
        // ,{
        //     name: "OVC/OTIP Grantees",
        //     values: ["Organizations"]
        // }
        ]
    },
    {
        name: "Care Coordination",
        state_location: "TX",
        charts: [
            {
                name: "Referrals",
                values: ["Total Referrals", "Individuals with DFPS Cases", "Referral Sources", "Referral Sources Over Time", "Referrals By Type Over Time"]
            },
            {
                name: "Identified, Recovered, Placements",
                values: ["Total in Care Coordination (all time)", "Individuals in Care Coordination", "Identified and Recovered Victims", "Recovered/Re-recovered individuals returned or placed within 72 hours", "Recovered/Re-recovered individuals returned or placed within 72 hours (over time)", "Total Placements (72 hours)", "Recovered/Re-recovered individuals returned or placed during the first 15 days", "Recovered/Re-recovered individuals returned or placed during the first 15 days (over time)", "Total Placements (15 days)"]
            },{
                name: "Meetings & Service Coordination",
                values: ["Meetings Held", "Cases Closed", "Individual Entered DFPS Conservatorship or FBSS", "Meetings By Type", "Meetings By Type (over time)", "Forensic Interviews and CST Case Reviews"]
            },{
                name: "Location Days",
                values: ["Total Days In Care Coordination", "Location Days by Type", "Location Days by Type (over time)", "Paid/Unpaid Days"]
            },{
                name: "Outcome Measures",
                values: ["Placements within 72 Hours", "Guardians following CC recommendations"]
            }
        ]
    },
    {
        name: "Care Coordination",
        state_location: "LA",
        charts: [
            {
                name: "Overview",
                values: ["Total Individuals Served by Region", "Total and New Individuals Served", "Total and New Individuals Served by Victim Type", "Location of Trafficking", "Referrals and Contacts", "Screenings" ]
            },
            {
                name: "Demographics",
                values: ["Race", "Ethnicity", "Gender Identity", "Age", "Citizenship"]
            },
            {
                name: "Victimization",
                values: ["Trafficking Type", "Co-Occurring Victimization Type", "Special Classification Category"]
            },
            {
                name: "Victim Services",
                values: ["Services by Type", "Information and Referrals", "Personal Advocacy and Accompaniment", "Emotional Support, Safety, and Health", "Shelter and Housing", "Criminal or Civil Justice System Assistance", "Education, Employment, and Life Skills"]
            },
            {
                name: 'Partnerships',
                values: ['Partners Involved', 'Partnership Building']
            },
            {
                name: 'Training and Meetings',
                values: ['Training Types', 'Participant Types', 'Care Coordination Meetings', 'Funding']
            }, //  'Participant Types'
            {
                name: 'Activities',
                values: ['Community Outreach', 'Law Enforcement']
                //'Community Outreach', 'Law Enforcement'
            }
        ]
    },
    {
        name: "Screenings",
        state_location: "TX",
        charts: [
            {
                name: 'Screenings Information',
                values: ['All Screenings', 'Screenings and Individuals Screened', 'Screenings by Location', 'Screenings by Location and Population', 'Screenings by Concern Level']
            },
            {
                name: "Demographics",
                values: ["Age", "Gender", "Race", "Ethnicity", "Age + Gender", "Gender + Race", "Age + Race", "Sexual Orientation + Concern Level", "Gender Identity + Concern Level", "Disability + Concern Level", "Education Level + Concern Level"]
            },
            {
                name: "Indicators",
                values: ["All Indicators", "Indicators By Category", "Indicator Prevalence", "Indicator Relationship to CSE-IT Score (Clear Concern)"]
            },
        ]
    },
    {
        name: "Screenings",
        state_location: "LA",
        charts: [
            {
                name: 'Screenings Information',
                values: ['All Screenings', 'Screenings and Individuals Screened', 'Screenings by Location', 'Screenings by Location and Population', 'Screenings by Concern Level']
            },
            {
                name: "Demographics",
                values: ["Age", "Gender", "Race", "Ethnicity", "Age + Gender", "Gender + Race", "Age + Race", "Sexual Orientation + Concern Level", "Gender Identity + Concern Level", "Disability + Concern Level", "Education Level + Concern Level"]
            },
            {
                name: "Indicators",
                values: ["All Indicators", "Indicators By Category", "Indicator Prevalence", "Indicator Relationship to CSE-IT Score (Clear Concern)"]
            },
        ]
    },
    {
        name: "Locations",
        state_location: "LA",
        charts: [
            {
                name: "Trafficking Locations",
                values: ["Parish of Trafficking", "Parish of Origin", "Parish of Trafficking Over Time (Top Ten)", "Parish of Origin Over Time (Top Ten)"]
            }
        ]
    },
    {
        name: "Law Enforcement",
        state_location: "TX",
        charts: [
            {
                name: "Case and Agency Information",
                values: [ "Cases by Arresting Agency", "Cases by Court Agency", "Arrest Cases by Location and Population", "Cases by Year of Arrest (by Offense Group)"]
                // "Case Duration (Average Time Between Date of Offense and Date of Arrest)", "Case Duration (Average Time Between Date of Arrest and Court Disposition Date)""Cases by Agency (Prosecuting Agency (ORIP))", , "Cases by Year of Arrest (Top Ten Offenses)" "Cases by Year of Arrest (by Agency)",
            },
            {
                name: "Offender Demographics",
                values: [ "Age",  "Race", "Gender"]
                //"Age (by Penal Code Chapters)", "Race (by Penal Code Chapters)", "Ethnicity (by Penal Code Chapters)", "Ethnicity (by Offense)", "Gender (by Penal Code Chapters)", "Citizenship (by Penal Code Chapters)", "Citizenship (by Offense)",
            }
        ]
    },
    {
        name: "Law Enforcement",
        state_location: "LA",
        charts: [
            {
                name: "Case and Agency Information",
                values: ["Cases by Arresting Agency", "Cases by Court Agency", "Arrest Cases by Location and Population", "Cases by Year of Arrest (by Offense Group)"]
                // "Cases by Year of Arrest (by Agency)",  "Case Duration (Average Time Between Date of Offense and Date of Arrest)", "Case Duration (Average Time Between Date of Arrest and Court Disposition Date)""Cases by Agency (Prosecuting Agency (ORIP))", , "Cases by Year of Arrest (Top Ten Offenses)"
            },
            {
                name: "Offender Demographics",
                values: [ "Age",  "Race", "Gender"]
                //"Age (by Penal Code Chapters)", "Race (by Penal Code Chapters)", "Ethnicity (by Penal Code Chapters)", "Ethnicity (by Offense)", "Gender (by Penal Code Chapters)", "Citizenship (by Penal Code Chapters)", "Citizenship (by Offense)",
            }
        ]
    },
    {
        name: "Federal Cases",
        state_location: "National",
        charts: [
            {
                name: "Case Information",
                values: ["Federal Cases by Year", "Trafficking Locations", "Known Period of Exploitation", "Sex Trafficking Type", "Labor Trafficking Type"]
            },
            {
                name: "Demographics - Defendants",
                values: ["Age", "Race/Ethnicity", "Gender", "Country of Origin"]
            },
            {
                name: "Demographics - Victims",
                values: ["Adult/Minor", "Gender", "Country of Origin"]
            },
            {
                name: "Methods",
                values: ["International Cases", "Interstate Cases", "Transportation Method", "Recruitment Method", "Crime Type"]
            }
        ]
    },
    {
        name: "Federal Cases",
        state_location: "TX",
        charts: [
            {
                name: "Case Information",
                values: ["Federal Cases by Year", "Trafficking Locations", "Known Period of Exploitation", "Sex Trafficking Type" , "Labor Trafficking Type"]
            },
            {
                name: "Demographics - Defendants",
                values: ["Age", "Race/Ethnicity", "Gender", "Country of Origin"]
            },
            {
                name: "Demographics - Victims",
                values: ["Adult/Minor", "Gender", "Country of Origin"]
            },
            {
                name: "Methods",
                values: ["International Cases", "Interstate Cases", "Transportation Method", "Recruitment Method", "Crime Type"]
            }
        ]
    },
    {
        name: "Federal Cases",
        state_location: "LA",
        charts: [
            {
                name: "Case Information",
                values: ["Federal Cases by Year", "Trafficking Locations", "Known Period of Exploitation", "Sex Trafficking Type", "Labor Trafficking Type"]
            },
            {
                name: "Demographics - Defendants",
                values: ["Age", "Race/Ethnicity", "Gender", "Country of Origin"]
            },
            {
                name: "Demographics - Victims",
                values: ["Adult/Minor", "Gender", "Country of Origin"]
            },
            {
                name: "Methods",
                values: ["International Cases", "Interstate Cases", "Transportation Method", "Recruitment Method", "Crime Type"]
            }
        ]
    },
    {
        name: "State Policy",
        state_location: "National",
        charts: [
            {
                name: "Policies Passed",
                values: ["Human Trafficking Policies Passed", "Sentencing Requirements", "Policy Prevalence"]
            },

        ]
    },
    {
        name: "State Policy",
        state_location: "TX",
        charts: [
            {
                name: "Policies Passed",
                values: ["Human Trafficking Policies Passed","Sentencing Requirements", "Policy Prevalence"]
            },

        ]
    },
    {
        name: "State Policy",
        state_location: "LA",
        charts: [
            {
                name: "Policies Passed",
                values: ["Human Trafficking Policies Passed", "Sentencing Requirements", "Policy Prevalence"]
            },

        ]
    },
    {
        name: "Texas Department of Criminal Justice",
        state_location: "TX",
        charts: [
            {
                name: "Offense Information",
                values: ["Inmates by Year", "Length of Sentence", "Time Between Offense and Sentence Date", "Offenses By Chapter Over Time"]
            },
            {
                name: "Inmate Demographics",
                values: ["Age",  "Race/Ethnicity", "Gender",]
            }
        ]
    }, {
        name: "Demand",
        state_location: "TX",
        charts: [
            {
                name: "Demand Information",
                values: ["Ad Posts by Location", "Ad Posts by Location and Population"]
            }
        ]
    } , {
        name: "Demand",
        state_location: "LA",
        charts: [
            {
                name: "Demand Information",
                values: ["Ad Posts by Location", "Ad Posts by Location and Population"]
            }
        ]
    }, {
        name: "News",
        state_location: "TX",
        charts: [
            {
                name: "Trafficking Types",
                values: ["Trafficking Type", "Trafficking Sub-Type"]
            }, {
                name: "Methods",
                values: ["Recruitment Method", "Control Method", "Transportation Methods"]
            }
        ]
    } , {
        name: "Hotline",
        state_location: "TX",
        charts: [
            {
                name: "Hotline Information",
                values: ["National Hotline Situations", "National Hotline Situations by Location", "National Hotline Situations by Location and Population"]
            }
        ]
    },
    {
        name: "Hotline",
        state_location: "LA",
        charts: [
            {
                name: "Hotline Information",
                values: ["National Hotline Situations", "National Hotline Situations by Location", "National Hotline Situations by Location and Population"]
            }
        ]
    },
    {
        name: "Businesses of Interest",
        state_location: "TX",
        charts: [
            {
                name: "Business Information",
                values: ["Businesses by Year", "Businesses by Location", "Businesses by Location and Population", "Total Businesses"]
            }
        ]
    } ,{
        name: "Businesses of Interest",
        state_location: "LA",
        charts: [
            {
                name: "Business Information",
                values: ["Businesses by Year", "Businesses by Location", "Businesses by Location and Population", "Total Businesses"]
            }
        ]
    } , {
        name: "Community Risk",
        state_location: "TX",
        charts: [
            {
                name: "Risk Prevalence",
                values: ["Counties with Prevalence of Risk"]
            }
        ]
    }
];
