import React, { useEffect } from "react";
import { statepolicydata } from "../../GraphComponents/GraphVariables/StatePolicyData";

import SingleCard from "../../../../Components/Card";
import { StackedBarVariableXGraph } from "../../GraphComponents/StackedBarVariableXAxis";
import HorizontalBarChart from "../../GraphComponents/HorizontalBarChart";
import { GroupedBarVariableLegend } from "../../GraphComponents/GroupedBarVariableLegend";

// import CasesByYearTable from './Tables/CasesByYearTable'
import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'


export default function StatePolicy(props) {
  // eslint-disable-next-line no-unused-vars
  const [graphData, setGraphData] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [graphStackedData, setGraphStackedData] = React.useState([]);

  useEffect(() => {
    console.log(props.data)
    setGraphData(props.data);
    setGraphStackedData(props.data);
  }, [props.data]);

  return (
    <div>
      <div>
        <SingleCard
          width="100%"
          id="Policies Passed Human Trafficking Policies Passed"
          title="Policies Passed  >  Human Trafficking Policies Passed"
          graph={
            <StackedBarVariableXGraph
              data={props.data.policies}
              xaxis="Time"
              yaxis="Total Policies"
              keys={statepolicydata.policies}
              type="cc"
              fontSize="true"
              colors="#4E79A7"
            />
          }
          table={
            // <CasesByYearTable
            //   data={props.data.policies}
            //   keys={statepolicydata.policies}
            //   id="Human Trafficking Policies Passed"
            //   columnHeaders={['Year', 'Prevention Policies', 'Protection Policies', 'Prosecution Policies', "Total Policies Passed"]}
            //   columnVars={[['xaxis'], ['prevention', 'protection', 'prosecution', 'total']]}
            //   differentTotalVars={true}              
            // />
            <TableWithTotal
              id="Human Trafficking Policies Passed"
              data={props.data.policies ?? []}
              headers={['Year', 'Prevention Policies', 'Protection Policies', 'Prosecution Policies', 'Total Policies Passed']}
              columnKeys={['xaxis', 'prevention', 'protection', 'prosecution', 'total']}
              customTotalRowData={props.data.policies[props.data.policies.length - 1]}
            />
          }
          definitions={[["", 'This chart displays the total number of Prevention Policies, Protection Policies, and Prosecution Policies that have been passed by states, based on selected filters. Full descriptions of each policy can be found in the State Policy Data Glossary. The Location filter applies to this chart.'],
          ["", 'Prevention Policies aim to prevent human trafficking (such as through education and training) and include the following policies:'],
          ["", <ul><li>Education for Students</li> <li>Regulation/Licensing Restrictions</li> <li>Reporting</li> <li>Task Force</li> <li>Training for Educators</li> <li>Training for Law Enforcement</li></ul>],
          ["", 'Protection Policies aim to protect victims of human trafficking and include the following policies:'],
          ["", <ul><li>Affirmative Defense</li> <li>Civil Action</li> <li>Hotline Posting</li> <li>Safe Harbor</li> <li>Survivor Assistance</li> <li>Trauma-informed CSES Screening</li> <li>Vacating Prior Convictions</li></ul>],
          ["", 'Prosecution Policies are related to the prosecution of or investigation of human trafficking crimes and include the following policies:'],
          ["", <ul><li>Asset Forfeiture</li> <li>Buyers Policy</li> <li>Increased Investigative Tools for Law Enforcement</li> <li>Low Burden of Proof for Minors</li> <li>Mistake of Age</li> <li>Restitution</li></ul>]
          ]}
          addToReports={props.addToReportPage}
        />
        <SingleCard
          width="100%"
          id="Policies Passed Sentencing Requirements"
          title="Policies Passed  >  Sentencing Requirements"
          graph={
            <GroupedBarVariableLegend
              data={props.data.sentencingRequirements ?? []}
              keys={statepolicydata.sentencingRequirements}
              xaxis="Sentence Category"
              yaxis="Sentence Length in Years"
              multipleYMaxValues={['min_sex_minor1', 'min_sex_minor2', 'max_sex_minor1', 'max_sex_minor2', 'min_sex_ad1', 'min_sex_ad2', 'max_sex_ad1', 'max_sex_ad2', 'min_lab_min1', 'min_lab_min2', 'max_lab_min1', 'max_lab_min2', 'min_lab_ad1', 'min_lab_ad2', 'max_lab_ad1', 'max_lab_ad2']}
              handleStateClick={props.handleStateClick}
              activeCategory={props.statePolicyStateSelection}
              statePolicyStateSelection={props.statePolicyStateSelection}
            />
          }
          table={
            <TableWithTotal
              noTotalRow
              id="Sentencing Requirements"
              data={props.data.sentencingTableData ?? []}
            headers={['State', 'Sentencing Category', 'Years']}
            columnKeys={['state', 'category', 'years']}
            />
          }
          definitions={[["", "This chart shows the state-level criminal sentencing requirements for an individual state as compared to the average state-level criminal sentencing requirements for all states, subject to applied filters. The default chart view shows criminal sentencing requirements as of the most recent year's data. Only the Time filter applies to this chart."]]}
          addToReports={props.addToReportPage}
        />

        <SingleCard
          width="100%"
          id="Policies Passed Policy Prevalence"
          title="Policies Passed  >  Policy Prevalence"
          graph={
            <HorizontalBarChart
              data={props.data.policyPrevalence}
              value="title"
              xaxis="Number of States"
              yaxis="Policy"
              specificColors={true}
              statePolicyPercent={true}
            />
          }
          table={
            <TableWithTotal
              id="State Policy Policy Prevalence"
              data={props.data.policyPrevalence ?? []}
              headers={['Policies Present', 'Total']}
              columnKeys={['title', 'total']}
              descending={true}
              totalKey='total'
              rightAlignTotal={true}
            />
          }
          definitions={[["", "This chart displays the prevalence of state-level human trafficking policies across all states, based on applicable filters. The default chart view shows policies present in states as of the most recent year's data. This means that for the applicable year, the policies were either passed or already effective. All filters apply to this chart."]]}
          addToReports={props.addToReportPage}
        />
      </div>
    </div>
  );
}
