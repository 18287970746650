import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
// import {
//   axisRight,
//   scaleLinear,
// } from "d3";
// import { withStyles } from "@material-ui/core/styles";
// import Checkbox from "@material-ui/core/Checkbox";

// const ColorCheckbox = withStyles({
//   root: {
//     color: (props) => props.color,
//     padding: "0px",
//     '&$checked': {
//       color: props => props.color,
//       padding: '0px'
//     },
//   },
//   checked: {},
// })((props) => <Checkbox color="default" {...props} />);

export const SingleBarGraph = (props) => {
  const wrapperRef = useRef();
  const svgRef = useRef();

  const [trendLine, setTrendLine] = useState(true);

  const handleLegendLineClick = (e) => {
    // If chartLine is set to false in this component, update props
    props.updateChartLineShowing(!trendLine)
    
    setTrendLine(!trendLine);
  };

  useEffect(() => {
    function endTooltip(){
      const boxes = document.querySelectorAll('.tooltip');

      boxes.forEach(box => {
        box.style.opacity = 0;
      });
    }
    window.addEventListener('scroll', endTooltip)
    //Setting the width and height
    var margin = { top: 20, right: 0, bottom: 90, left: 80 };
    var width;
    var height = 500;
    if (window.innerWidth > 1200 && window.innerWidth < 1440) {
      width = 900;
    } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
      width = 900;
    } else {
      width = 1200;
    }
    if (props.widthNeeded) {
      width = props.widthNeeded;
    } else if (props.report) {
      width = 700
    }

    // Formatting xaxis titles and bar colors
    const titles = props.keys.map((item) => {
      if (item.quarter) {
        return `${item.name} ${item.quarter}`;
      } else if (props.xaxisLabelsKey){
        return item[props.xaxisLabelsKey];
      } else {
        return item.name;
      }
    });

    var colorArray = props.keys.map((item) => {
      return item.color;
    });

    if (props.colors) {
      colorArray = [props.colors];
    }

    const colors = d3.scaleOrdinal(colorArray);
    //For reports
    var data;
    if (props.type === "cc") {
      data = props.data;
    } else {
      data = props.data.referrals;
    }

    if (data.length < 5) {
      width = 600;
    }

    //Calculating axis
    var max = null;

    max = d3.max(data, (d) => +d.value);

    //Selecting the svg element and removing past svg's (from filter)
    let svg = d3.select(svgRef.current);
    svg.selectAll("svg").remove();

    //Creating SVG
    var barsvg = svg
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + 0 + "," + 0 + ")");

    // Creating the tooltip
    var tooltip = d3
      .select(wrapperRef.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("color", "black")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("position", "fixed")
      .style("z-index", "-1")
      .style("padding", "5px");
    tooltip.append("div").attr("class", "title");
    tooltip.append("div").attr("class", "label");
    tooltip.append("div").attr("class", "percent");

    // set the ranges
    var x = d3
      .scaleBand()
      .domain(d3.range(data.length))
      .range([margin.left, width - margin.right])
      .padding(0.1);
    var y = d3
      .scaleLinear()
      .domain([0, max])
      .nice()
      .range([height - margin.bottom, margin.top]);

    var xAxis = (g) =>
      g.attr("transform", `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .tickFormat((i) => titles[i])
          .tickSizeOuter(0)
      );

    var formatxAxis = d3.format(".0f");
    var yAxis;
    if (max < 20) {
      yAxis = (g) =>
        g
          .attr("transform", `translate(${60},0)`)
          .call(d3.axisLeft(y).ticks(max).tickFormat(formatxAxis))
          .attr("class", "y-axis")
          .call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y)
          );
    } else {
      yAxis = (g) =>
        g
          .attr("transform", `translate(${80},0)`)
          .call(d3.axisLeft(y).ticks(null, data.y))
          .attr("class", "y-axis")
          .call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y)
          );
    }

    // Adding the bars
    barsvg
      .append("g")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .on("mouseover", onMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove)
      .attr("x", (d, i) => x(i))
      .attr("y", (d) => {
        if (d.value) {
          return y(d.value);
        } else if (d.total) {
          return y(d.total);
        }
      })
      .attr("height", (d) => {
        if (d.value) {
          return y(0) - y(d.value);
        } else if (d.total) {
          return y(0) - y(d.total);
        }
      })
      // .attr("width", x.bandwidth() > 90 ? 60 : x.bandwidth())
      //.attr("width", x.bandwidth() > 60 ? 60 : x.bandwidth())
      .attr("width", x.bandwidth())
      .attr("fill", (d, i) => {
        return colors(i);
      });

    var labelKeys = props.keys;
    var graphVariable = props.graphVariable ? props.graphVariable : "source";

    function onMouseOver(d) {
      tooltip.style("opacity", 1)
      tooltip.style("z-index", "9999");

      let label;
      for (let i = 0; i < labelKeys.length; i++) {
        if (d[graphVariable] === labelKeys[i].variable) {
          if (graphVariable === "age") {
            label = `Age: ${labelKeys[i].name}`;
          } else {
            label = labelKeys[i].name;
          }
        }
      }
      var total = d3.sum(
        data.map((d) => {
          return d.value;
        })
      );
      var percent =
        Math.round((1000 * (d.value ? d.value : d.total)) / total) / 10;
      // console.log("hotline d.value", d.value);
      // console.log("hotline d.total", d.total);
      // console.log("hotline total", total);
      tooltip.select(".title").text(label);
      tooltip
        .select(".label")
        .text(
          "Total: " +
            (d.value ? d.value : d.total)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      tooltip.select(".percent").text("Percent: " + percent + "%");
    }

    function onMouseOut() {
      tooltip.style("opacity", 0)
      .style("z-index", "-1");
    }

    function onMouseMove(d) {
      tooltip
        .style("opacity", 1)
        .style("z-index", "9999")
        .style("top", d3.event.clientY - 150 + "px")
        .style("left", d3.event.clientX - 150 + "px");
    }

    // x axis labels for report, else for graph
    if (props.report) {
      barsvg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .selectAll(".tick text")
        .style("font-size", props.xaxisFontSize ? props.xaxisFontSize : '12px')
        .call(wrap, x.bandwidth()); // 6
        
    } else {
      barsvg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .selectAll(".tick text")
        .style("font-size", props.xaxisFontSize ? props.xaxisFontSize : '12px')
        .call(wrap, x.bandwidth()); // 25
    }

    barsvg.append("g").call(yAxis);

    // text label for the x axis
    barsvg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "translate(" + width / 2 + " ," + (height - 5) + ")")
      .style("text-anchor", "middle")
      .text(props.xaxis);

    // text label for the y axis
    barsvg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(props.yaxis);

    // wrap x axis labels function
    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/[ /]+/).reverse(), // /\s+/
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = 0.5, // parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width && line.length > 1) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }

    // ********************* Start of Trend line *********************
    if (props.trendLine) {
      // eslint-disable-next-line array-callback-return
      let lineData = data.filter((item, key) => {
        if (item.value) {
          return item;
        } else  {
          item.value = "0";
          return item;
        }
      });

      //if (lineData.length > 1) {
      if (trendLine && lineData.length > 1) {

      //Adding line and circle hover
      const totalLine = d3
        .line()
        .x((d, i) => x(i) + x.bandwidth() / 2)
        .y((d) => y(d.value ? d.value : d.total));

      barsvg
        .append("path")
        .datum(lineData)
        .attr("fill", "none")
        .attr("stroke", "#212121")
        .attr("stroke-width", 2)
        .attr("class", "line trendLine")
        .attr("d", totalLine);

      let Tooltip = d3
        .select(wrapperRef.current)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px");
      Tooltip.append("div").attr("class", "label");
      Tooltip.append("div").attr("class", "percent");

      // Tberry added code here
      const mouseover = (d) => {
        Tooltip.style("opacity", 1);
        Tooltip.style("z-index", "9999");
        if (this.props.imbByYear) {
          var percent = 0;
          for (let i = 1; i < this.props.data.length; i++) {
            console.log("d", d);

            console.log("name", this.props.data[i].name);
            if (this.props.data[i].name === d.name) {
              percent = (
                this.props.data[i].value / this.props.data[i - 1].value
              ).toFixed(2);
            }
          }
          if (d.name !== "2016") {
            Tooltip.select(".percent").text("Percent Change: " + percent + "%");
          } else if (d.name === "2016") {
            Tooltip.select(".percent").text("");
          }
        }
      };
      const mousemove = (d) => {
        if (props.lineTitle) {
          Tooltip.select(".label").text(
            props.lineTitle +
              ": " +
              (d.value ? d.value : d.total)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
          Tooltip.style("top", d3.event.clientY - 100 + "px") // - 75
            .style("left", d3.event.clientX - 150 + "px");
        } else {
          Tooltip.select(".label").text(
            props.trendLineLegend +
              ": " +
              (d.value ? d.value : d.total)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
          Tooltip.style("top", d3.event.clientY - 100 + "px").style(
            "left",
            d3.event.clientX - 150 + "px"
          );
          if (this.props.imbByYear) {
            var percent = 0;
            for (let i = 1; i < this.props.data.length; i++) {
              console.log("d", d);
              if (this.props.data[i].name === d.name) {
                console.log("data[i] name", this.props.data[i].name);
                console.log("d name", d.name);
                percent = (
                  this.props.data[i].value / this.props.data[i - 1].value
                ).toFixed(2);
              }
            }
            if (d.name !== "2016") {
              Tooltip.select(".percent").text(
                "Percent Change: " + percent + "%"
              );
            } else if (d.name === "2016") {
              Tooltip.select(".percent").text("");
            }
          }
        }
      };
      const mouseleave = (d) => {
        Tooltip.style("opacity", 0);
        Tooltip.style("z-index", "-1");
      };

      barsvg
        .append("g")
        .selectAll("dot")
        .data(lineData)
        .enter()
        .append("circle")
        .attr("class", "myCircle")
        .attr("cx", function (d, i) {
          return x(i) + x.bandwidth() / 2;
        })
        .attr("cy", function (d) {
          return y(d.value ? d.value : d.total);
        })
        .attr("r", 3)
        .attr("stroke", "#000000")
        .attr("stroke-width", 3)
        .attr("fill", "#000000")
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
    // else if (props.percentLine) {
    //   // eslint-disable-next-line array-callback-return
    //   let lineData = data.filter((item, key) => {
    //     if (item.value) {
    //       return item;
    //     }
    //   });
    //   console.log(lineData)
    //   lineData[0].percent = 0;
    //   let percentageArray = [lineData[0]];
    //   for (let i = 1; i < lineData.length; i++){
    //     let newValue = (lineData[i].value - lineData[i - 1].value)/lineData[i - 1].value;
    //     lineData[i].percent = newValue * 100;
    //     percentageArray.push(lineData[i])
    //   }
    //   console.log(percentageArray)

    //   let y2 = d3.scaleLinear().range([height - margin.bottom, margin.top]);

    //   let percentMax = d3.max(percentageArray, (d) => +d.percent);
    //   console.log(percentMax)

    //   y2.domain([0, percentMax]);

    //   let y2Axis = d3.axisRight(y2).tickFormat(d => d + "%")

    //   svg
    //     .append("g")
    //     .attr("class", "y axis")
    //     .attr("transform", `translate(${width}, 0 )`)
    //     .call(y2Axis);

    //   //Adding line and circle hover
    //   const percentLine = d3
    //     .line()
    //     .x((d, i) => x(i) + x.bandwidth() / 2)
    //     .y((d) => y2(d.percent));

    //   barsvg
    //     .append("path")
    //     .datum(percentageArray)
    //     .attr("fill", "none")
    //     .attr("stroke", "#212121")
    //     .attr("stroke-width", 2)
    //     .attr("class", "line trendLine")
    //     .attr("d", percentLine);

    //   let Tooltip = d3
    //     .select(wrapperRef.current)
    //     .append("div")
    //     .style("opacity", 0)
    //     .attr("class", "tooltip")
    //     .style("opacity", 0)
    //     .style("background-color", "white")
    //     .style("color", "black")
    //     .style("border", "solid")
    //     .style("border-width", "2px")
    //     .style("border-radius", "5px")
    //     .style("position", "fixed")
    //     .style("z-index", "-1")
    //     .style("padding", "5px");
    //   Tooltip.append("div").attr("class", "label");
    //   Tooltip.append("div").attr("class", "percent");

    //   // Tberry added code here
    //   const mouseover = (d) => {
    //     Tooltip.style("opacity", 1);
    //     Tooltip.style("z-index", "9999");
    //     if (this.props.imbByYear) {
    //       var percent = 0;
    //       for (let i = 1; i < this.props.data.length; i++) {
    //         console.log("d", d);

    //         console.log("name", this.props.data[i].name);
    //         if (this.props.data[i].name === d.name) {
    //           percent = (
    //             this.props.data[i].value / this.props.data[i - 1].value
    //           ).toFixed(2);
    //         }
    //       }
    //       if (d.name !== "2016") {
    //         Tooltip.select(".percent").text("Percent Change: " + percent + "%");
    //       } else if (d.name === "2016") {
    //         Tooltip.select(".percent").text("");
    //       }
    //     }
    //   };
    //   const mousemove = (d) => {
    //     if (props.lineTitle) {
    //       Tooltip.select(".label").text(
    //         props.lineTitle +
    //           ": " +
    //           (d.value ? d.value : d.total)
    //             .toString()
    //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //       );
    //       Tooltip.style("top", d3.event.clientY - 100 + "px") // - 75
    //         .style("left", d3.event.clientX - 150 + "px");
    //     } else {
    //       Tooltip.select(".label").text(
    //         props.trendLineLegend +
    //           ": " +
    //           (d.value ? d.value : d.total)
    //             .toString()
    //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //       );
    //       Tooltip.style("top", d3.event.clientY - 100 + "px").style(
    //         "left",
    //         d3.event.clientX - 150 + "px"
    //       );
    //       if (this.props.imbByYear) {
    //         var percent = 0;
    //         for (let i = 1; i < this.props.data.length; i++) {
    //           console.log("d", d);
    //           if (this.props.data[i].name === d.name) {
    //             console.log("data[i] name", this.props.data[i].name);
    //             console.log("d name", d.name);
    //             percent = (
    //               this.props.data[i].value / this.props.data[i - 1].value
    //             ).toFixed(2);
    //           }
    //         }
    //         if (d.name !== "2016") {
    //           Tooltip.select(".percent").text(
    //             "Percent Change: " + percent + "%"
    //           );
    //         } else if (d.name === "2016") {
    //           Tooltip.select(".percent").text("");
    //         }
    //       }
    //     }
    //   };
    //   const mouseleave = (d) => {
    //     Tooltip.style("opacity", 0);
    //     Tooltip.style("z-index", "-1");
    //   };

    //   barsvg
    //     .append("g")
    //     .selectAll("dot")
    //     .data(lineData)
    //     .enter()
    //     .append("circle")
    //     .attr("class", "myCircle")
    //     .attr("cx", function (d, i) {
    //       return x(i) + x.bandwidth() / 2;
    //     })
    //     .attr("cy", function (d) {
    //       return y(d.value ? d.value : d.total);
    //     })
    //     .attr("r", 3)
    //     .attr("stroke", "#000000")
    //     .attr("stroke-width", 3)
    //     .attr("fill", "#000000")
    //     .on("mouseover", mouseover)
    //     .on("mousemove", mousemove)
    //     .on("mouseleave", mouseleave);
    } else {
      svg.selectAll(".trendLine").remove();
      svg.selectAll(".dot").remove();
      svg.selectAll(".myCircle").remove();
    }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
      props.data,
    props.colors,
    props.keys,
    props.yaxisMaxValue,
    props.trendLineLegend,
      props.report,
      props.widthNeeded,
    props.trendLine,
    trendLine,
    props.xaxis,
    props.yaxis,
    props.percentLine,
    props.percentLegend,
    props.line,
    props.lineTitle,
    props.graphVariable,
    props.type,
  ]);

  return (
    <div id={props.id} className={props.smalltext ? "graph" : "graph1"}>
      <div ref={wrapperRef} style={{ position: "relative" }}>
        <div ref={svgRef} />
      </div>

      {props.trendLine ? (
        <div
          className="field"
          style={{
            display: "flex",
            marginLeft: "20px",
            alignItems: "center",
          }}
        >
          {props.report ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                style={{
                  display: "flex",
                  width: "15px",
                  height: "15px",
                  backgroundColor: "black",
                  border: "none",
                }}
              ></button>
              <label style={{ fontSize: "12px", marginLeft: "5px" }}>
                {props.trendLineLegend}
              </label>
            </div>
          ) : (
            <label
              className='chartCheckboxContainer'
            >
                {props.trendLineLegend}
                <input
                  type="checkbox"
                  className='chartCheckboxes'
                  // checked={trendLine}
                  defaultChecked={trendLine}
                />
                <span
                  className="chartCheckmark"
                  onClick={(e) => handleLegendLineClick(e)}
              style={{
                backgroundColor: trendLine ? 'black' : 'transparent',
                borderColor: trendLine ? 'black' : '#0000008a'
              }}
                />
            </label>
            // <div>
            //   {trendLine ? (
            //     <ColorCheckbox
            //       checked={true}
            //       color="black"
            //       onClick={(e) => handleLegendLineClick(e)}
            //     />
            //   ) : (
            //     <ColorCheckbox
            //       color="black"
            //       checked={false}
            //       style={{ padding: "0px" }}
            //       onClick={(e) => handleLegendLineClick(e)}
            //     />
            //   )}
            //   <label style={{ fontSize: "12px", marginLeft: "5px" }}>
            //     {props.trendLineLegend}
            //   </label>
            // </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
