import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { useAppContext } from "../../Lib/UserContext";
import logo from "../../Assets/Images/lighthouse-login-logo.png";
import "./ForgotPassword.css";
const useStyles = makeStyles((theme) => ({
  primaryButton: {
    backgroundColor: "#000000 !important",
    borderRadius: "20px",
    color: "white",
    width: "100%",
    textTransform: "none",
  },
  emailInputMargin: {
    marginBottom: "24px",
  },
  
  inputField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#41484D",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1957CD",
      },
    },
  },
}));
export default function ForgotPassword(props) {
  const classes = useStyles();
  const { email } = useAppContext();
  const [value, setValue] = React.useState({});
  const [codeSent, setCodeSent] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isSendingCode, setSendingCode] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [confirming, setConfirming] = React.useState(false);
  useEffect(() => {
    setValue({ email: email });
  }, [email]);
  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };
  const handleClose = () => {
    props.callBack();
  };
  const handleSendCodeClick = async (event) => {
    event.preventDefault();
    setSendingCode(true);
    try {
      await Auth.forgotPassword(value.email);
      setCodeSent(true);
    } catch (e) {
      alert(e.message);
      setSendingCode(false);
    }
  };
  const handleConfirmClick = async (event) => {
    event.preventDefault();
    setConfirming(true);
    if (value.password !== value.confirmPassword) {
      alert("Passwords do not match.");
      setConfirming(false);
      return;
    }
    if (
      new RegExp(
        "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
      ).test(value.password) === false
    ) {
      alert(
        "The password must contain at least 8 characters and at least: 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character (!@#$%^&*?,._), and must not contain repeating or consecutive characters and/or use part of your name or email."
      );
      setConfirming(false);
      return;
    }
    if (new RegExp(/(\w)\1{2,}/g).test(value.password.toLowerCase())) {
      // If password contains more than 2 repeating chars(e.g., aaa)
      alert("The password may not contain repeating characters.");
      setConfirming(false);
      return;
    }
    const testSequential = (password) => {
      // If both loops fail to find more than 3 sequential characters, the function returns true, otherwise it returns false.
      // (e.g., 1234abcd)
      // Check for sequential numerical characters
      for (var i in password) {
        if (
          +password[+i + 1] === +password[i] + 1 &&
          +password[+i + 2] === +password[i] + 2 &&
          +password[+i + 3] === +password[i] + 3
        ) {
          setConfirming(false);
          return false;
        }
      }
      // Check for sequential alphabetical characters
      for (var k in password) {
        if (
          String.fromCharCode(password.charCodeAt(k) + 1) ===
            password[+k + 1] &&
          String.fromCharCode(password.charCodeAt(k) + 2) ===
            password[+k + 2] &&
          String.fromCharCode(password.charCodeAt(k) + 3) === password[+k + 3]
        ) {
          setConfirming(false);
          return false;
        }
        return true;
      }
    };
    let noSequentials = testSequential(value.password);
    if (!noSequentials) {
      // sequential chars
      alert("The password may not contain consecutive characters.");
      setConfirming(false);
      return;
    }
    const passwordContainsEmail = (email, password) => {
      // 3
      for (let i = 0; i + 3 < email.length; i++) {
        // if password contains 3 sequential chars from their email, return false, else true
        if (password.indexOf(email.substring(i, i + 3)) !== -1) {
          setConfirming(false);
          return false;
        }
        return true;
      }
    };
    let containsEmailChars = passwordContainsEmail(
      value.email.toLowerCase(),
      value.password.toLowerCase()
    );
    if (!containsEmailChars) {
      alert("The password may not contain part of your email");
      setConfirming(false);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(value.email, value.code, value.password);
      setConfirmed(true);
    } catch (e) {
      alert(e.message);
      setConfirming(false);
    }
  };
  const renderRequestCodeForm = () => {
    return (
      <div className="recovery-parent-div">
        <img src={logo} alt="Lighthouse Data Initiative" width="150px" />
        <h3 className="recoveryHeader" style={{ margin: "0" }}>
          Forgot Password
        </h3>
        <form className="password-recover-form" onSubmit={handleSendCodeClick}>
          <p className="recovery-email-label" style={{ margin: "0" }}>
            Enter your email, we’ll send you a code to reset your password.
          </p>
          <TextField
            className={`${classes.emailInputMargin} ${classes.inputField}`}
            id="email-textfield"
            name="email"
            value={value.email}
            label={<span className={classes.emailLabel}>Email</span>}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <AccountCircle />
            //     </InputAdornment>
            //   ),
            // }}
          />

          <hr className="dividerLine" />
          <div className="button-div">
            <Button
              className={`${classes.primaryButton} continue-button btnHover`}
              type="submit"
            >
              Continue
            </Button>
          </div>
        </form>
      </div>
    );
  };
  const renderConfirmationForm = () => {
    return (
      <div className="setPassword-parent-div">
        <img src={logo} alt="Lighthouse Data Initiative" width="150px" />
        <div className="setPassword-para-div">
          <h3 className="setPassword-header" style={{ margin: "0" }}>
            Forgot Password
          </h3>
          <p className="setPassword-para" style={{ margin: "0" }}>
            {" "}
            Check your email for your confirmation code.
          </p>
        </div>
        <form className="setPassword-form" onSubmit={handleConfirmClick}>
          <TextField
            className={`${classes.margin} ${classes.inputField} loginInputField mulish-font`}
            id="code"
            name="code"
            value={value.code}
            fullWidth
            type="number"
            label="Code"
            variant="outlined"
            placeholder={'12345'}
            onChange={handleChange}
          />
          <div className="setPassword-textfield-div">
            <TextField
              className={`${classes.margin} ${classes.inputField} setPassword-input-password loginInputField mulish-font`}
              id="password"
              name="password"
              value={value.password}
              fullWidth
              type="password"
              label="Password"
              variant="outlined"
              onChange={handleChange}
            />
          </div>
          <div style={{ color: "#41484D", paddingBottom: 24 }}>
            <ul style={{ marginTop: 5,marginBottom:0, fontFamily: "Mulish", fontSize: 12 }}>
              <li>Must contain at least 8 characters</li>
              <li>
                Must contain at least 1 uppercase letter, 1 lowercase letter, 1
                number, and 1 special character (!@#$%^&*?..__)
              </li>
              <li>May not contain repeating or consecutive characters</li>
              <li>May not contain part of your name or email</li>
            </ul>
          </div>

          <TextField
            className={`${classes.margin} ${classes.inputField} setPassword-input-conf`}
            id="confirmPassword"
            name="confirmPassword"
            value={value.confirmPassword}
            fullWidth
            type="password"
            label=" Confirm Password"
            variant="outlined"
            onChange={handleChange}
          />
          <div>
            <hr className="dividerLine" />
          </div>
          <div
            className="setPassword-button-div"
            style={{ paddingTop: "24px", fontFamily: "Mulish" }}
          >
            <Button
              className={`${classes.primaryButton} setPassword-btn btnHover`}
              type="submit"
            >
              Continue
            </Button>
          </div>
        </form>
      </div>
    );
  };
  const renderSuccessMessage = () => {
    return (
      <div className="success">
        <img
          className="forgotPasswordImage"
          src={logo}
          alt="Lighthouse Data Initiative"
          width="150px"
        />
        <div className="success-para-div">
          <p className="success-para-heading" style={{ margin: "0" }}>
            Your password has been reset!
          </p>
          <p className="success-para" style={{ margin: "0" }}>
            You can login with your new credentials.
          </p>
        </div>
        <div>
          <hr className="dividerLine" />
        </div>
        <div className="success-btn-div">
          <Button
            className={`${classes.primaryButton} success-btn btnHover`}
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth={"xl"}
    >
      <div style={{ padding: "24px" }}>
        {!codeSent
          ? renderRequestCodeForm()
          : !confirmed
          ? renderConfirmationForm()
          : renderSuccessMessage()}
      </div>
    </Dialog>
  );
}
