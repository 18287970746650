import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Login.css";
import logo from "../../Assets/Images/lighthouse-login-logo.png";
// import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import LockIcon from "@material-ui/icons/Lock";
import Checkbox from "@material-ui/core/Checkbox"; // Import Checkbox component
import FormControlLabel from "@material-ui/core/FormControlLabel"; // Import FormControlLabel component
import Button from "@material-ui/core/Button";
import { Auth, API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../Lib/UserContext";
import ForgotPassword from "./ForgotPassword";
import BackgroundImage from "../../Assets/Images/bg.jpeg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  loginWrapper: {
    borderRadius: "12px",
    backgroundColor: "#FFF",
    padding: "24px",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.10), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)",
  },
  buttonWrapper: {
    display: "flex",
    // justifyContent: "space-between",
    justifyContent: "center",
    alignItems: "center",
    margin: "24px 0 0 0",
  },
  headingTitle: {
    color: "#000",
    fontFamily: "Lora, serif",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "28px",
    marginBottom: "4px",
  },
  subHeading: {
    color: "#000",
    fontFamily: "Mulish",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    paddingBottom: "24px",
  },
  margin: {
    marginBottom: "24px",
  },

  inputFelid: {
    border: "1px solid  #71787E",
    padding: "24px 24px 0px",
    marginBottom: "24px",
    borderRadius: "4px 4px 0px 0px",
    height: "48px",
  },

  primaryButton: {
    fontFamily: "Mulish",
    backgroundColor: "black !important",
    borderRadius: "50px",
    color: "white",
    width: "204px",
    height: "40px",
    padding: "10px 24px 10px 24px",
  },
  styleTextLink: {
    color: "#1957CD",
    fontFamily: "Mulish",
    fontSize: "16px",

    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    cursor: "pointer",
  },
  textField: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottom: "none",
    },

    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
  checkboxText: {
    color: " #344054",
    fontFamily: "Mulish",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
  },
  inputCheckBox: {
    width: "18px",
    height: "18px",
    borderRadius: "2px",
    margin: "0 12px 0 12px",
    color: "rgba(0, 0, 0, 0.54)",
    "&.Mui-checked": {
      color: "#1957CD",
    },
  },
  dividerLogin: {
    marginLeft: "-24px",
    marginRight: "-24px",
    width: "calc(100% + 48px)",
  },
}));

const Divider = ({ styleClass }) => (
  <svg
    className={styleClass}
    height="1"
    viewBox="0 0 480 1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="0" y1="0" x2="480" y2="0" stroke=" #E4E7EC" strokeWidth="1" />
  </svg>
);

export default function Login(props) {
  const classes = useStyles();
  const [userData, setUserData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { userHasAuthenticated, setRole, setEmail, setStateLocation } =
    useAppContext();
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);


  useEffect(() => {
    let rememberEmail = localStorage.getItem('rememberEmail')
    let rememberExpire = localStorage.getItem('rememberExpireDate') 

    if (rememberExpire) {
      let todayDate = new Date()
      let todayFullDate = `${todayDate.getMonth() + 1} ${todayDate.getDate()}, ${todayDate.getFullYear()}`
      
      let expire = JSON.parse(rememberExpire)
      let expireDate = `${new Date(expire).getMonth() + 1} ${new Date(expire).getDate()}, ${new Date(expire).getFullYear()}`
      
      if (expireDate === todayFullDate) {
        localStorage.setItem('rememberEmail', '')
        localStorage.setItem('rememberExpireDate', '')
      } else if (rememberEmail) {
        let updateData = { ...userData }
        updateData.email = rememberEmail
        setUserData(updateData)
      }
    }
    // eslint-disable-next-line
  }, [])


  const handleChange = (event) => {
    var newValue = { ...userData };
    newValue[event.target.name] = event.target.value;
    setUserData(newValue);
  };

  const handleCheckboxChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleRememberMe = () => {
    if (rememberMe && userData.email) {
      localStorage.setItem('rememberEmail', userData.email);
      // Add date to remove email in 30 days
      let date = new Date(); // Now
      let expireDate = date.setDate(date.getDate() + 30)
      localStorage.setItem('rememberExpireDate', expireDate);
    }
  }

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  // const HandleAccountAccess = () => {
  //   window.open("https://www.alliesagainstslavery.org/lighthouse", "_blank");
  // };

  const callBack = () => {
    setForgotPassword(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    handleRememberMe()

    if (!userData.email || !userData.password) {
      alert(
        "Missing email or password: Please contact lighthouse@alliesagainstslavery.org for help logging in."
      );
      setLoading(false);
      return;
    }

    Auth.signIn(userData.email.toLowerCase(), userData.password)
      .then((response) => {
        setEmail(userData.email.toLowerCase());
        API.post("lighthouse-data-initiative", "getuserrole", {
          body: {
            email: userData.email.toLowerCase(),
          },
        })
          .then((response) => {
            setRole(response[0].role);
            localStorage.setItem("role", response[0].role);
            localStorage.setItem("stateLocation", response[0].state_location);
            setStateLocation(response[0].state_location);
            userHasAuthenticated(true);
            history.push("/dashboard");
          })
          .catch((err) => {
            alert("You need to update your password!");
            history.push("/newuser");
          });
      })
      .catch((err) => {
        setLoading(false);
        if (err.code === "UserNotConfirmedException") {
          alert("User is not confirmed.");
        } else if (err.code === "NotAuthorizedException") {
          alert(
            "You entered the incorrect password or exceeded the password limit. Please try again."
          );
        } else if (err.code === "LimitExceededException") {
          alert("Attempt limit exceeded. Please try again after some time.");
        } else if (err.code === "UserNotFoundException") {
          alert("User not found. Please try again.");
        } else if (err.code === "InvalidParameterException") {
          alert("Please enter a password.");
        } else {
          alert(
            "Please contact lighthouse@alliesagainstslavery.org for help logging in."
          );
        }
        // if (error.code === "UserNotFoundException") {
        //   API.post("lighthouse-data-initiative", "addcoreuser", {
        //     body: {
        //       email: userData.email,
        //       password: userData.password,
        //     },
        //   }).then((response) => {
        //     console.log(response);
        //     if (response) {
        //       Auth.signIn(userData.email, userData.password)
        //         .then((user) => {
        //           if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        //             Auth.completeNewPassword(user, userData.password)
        //               .then((user) => {
        //                 API.post("lighthouse-data-initiative", "addnewuser", {
        //                   body: {
        //                     email: userData.email,
        //                   },
        //                 }).then((response) => {
        //                   history.push("/dashboard/map");
        //                   userHasAuthenticated(true);
        //                   setEmail(userData.email);
        //                   setRole("Standard");
        //                 });
        //               })
        //               .catch((error) => {
        //                 console.log(error);
        //               });
        //           } else {
        //             setLoading(false);
        //             alert("User not found.");
        //           }
        //         })
        //         .catch((error) => {
        //           setLoading(false);
        //           alert("User not found.");
        //         });
        //     } else {
        //       setLoading(false);
        //       alert(error.message);
        //     }
        //   });
        // } else {
        //   setLoading(false);
        //   alert(error.message);
        // }
      });
  };

  return (
    <div className={classes.root}>
      <div className={`${classes.loginWrapper} mulish-font`}>
        <img src={logo} alt="Lighthouse Data Initiative" width="130px" />

        <h3 className={`${classes.headingTitle} lora-font`}>
          Login to Lighthouse
        </h3>
        <p className={`${classes.subHeading} mulish-font`}>
          Enter your credentials below to sign into Lighthouse.
        </p>
        <TextField
          className={`${classes.margin} ${classes.inputField}  loginInputField mulish-font`}
          id="email-textfield"
          name="email"
          value={userData.email}
          label='Email'
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
        <TextField
          className={`${classes.margin} ${classes.inputField} loginInputField mulish-font`}
          id="password-textfield"
          name="password"
          fullWidth
          type="password"
          label="Password"
          variant="outlined"
          onChange={handleChange}
        />

        <div className="checkboxForgotPassword mulish-font">
          <FormControlLabel
            className={classes.checkboxText}
            control={
              <Checkbox
                className={`${classes.inputCheckBox} checkBox-input `}
                checked={rememberMe}
                onChange={handleCheckboxChange}
                name="rememberMe"
                color="primary"
              />
            }
            label="Remember for 30 days"
          />

          <p
            className={`${classes.styleTextLink} mulish-font`}
            onClick={handleForgotPassword}
          >
            Forgot Password
          </p>
          <ForgotPassword open={forgotPassword} callBack={callBack} />
        </div>
        <Divider styleClass={classes.dividerLogin} />
        <div className={`${classes.buttonWrapper} mulish-font`}>
          {/* <p
            className={`${classes.styleTextLink} loginAccountLink mulish-font`}
            onClick={HandleAccountAccess}
          >
            Don't have an account?
          </p> */}
          <Button
            className={`${classes.primaryButton}  mulish-font btnHover`}
            onClick={handleSubmit}
            disabled={loading}
          >
            <span 
           
              style={{
                color: loading ? "rgb(228, 231, 236)" : "#FFF",
                textTransform: "none", fontFamily:"Mulish !important"
              }}
            >
              {loading ? "Loading.." : "Login"}
            </span>
          </Button>
          {/* <a className={classes.forgotPassword} href="https://lighthouse-pdf-images.s3-us-west-2.amazonaws.com/NAI_1513887977_5_Lighthouse+-+End+User+Agreement+(Final).pdf"
            target="_blank"
            rel="noopener noreferrer">
            End User Agreement
          </a> */}
        </div>
      </div>
    </div>
  );
}
