import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useAppContext } from '../../../Lib/UserContext';
import TimeYearFilter from './TimeYearFilter'
import ServicesTimeFilter from './Services/TimeFilter';
import ServicesYearFilter from './Services/YearFilter';
import ServicesLocationFilter from './Services/LocationFilter';
import ServicesLocationTypeFilter from './Services/LocationTypeFilter';
// Lousiana
import ServicesLAAgeFilter from './ServicesLA/AgeFilter';
import ServicesLAOrganizationFilter from './ServicesLA/OrganizationFilter';
import ServicesLATrafTypeFilter from './ServicesLA/TrafTypeFilter';
import ServicesLALocationFilter from './ServicesLA/LocationFilter';
import ServicesLALocationMapFilter from './ServicesLA/LocationMapFilter';
import ServicesLATimeFilter from './ServicesLA/TimeLAFilter';
import VictimStatusTimeFilter from './ServicesLA/VictimStatusFilter';


import CCTimeFilter from './CareCoordination/TimeFilter';
import CCLocationFilter from './CareCoordination/LocationFilter';
import CCLocationLAFilter from './CareCoordination/LocationLAFilter';
import TraffTypeLAFilter from './CareCoordination/TraffTypeLAFilter'
import IndividualsServedLAFilter from './CareCoordination/IndividualsServedLAFilter'

import ScreeningsTimeFilter from './Screenings/TimeFilter';
import ScreeningsLocationFilter from './Screenings/LocationFilter';
import ScreeningsOrgFilter from './Screenings/OrganizationFilter';
import ScreeningsScreeningsFilter from './Screenings/ScreeningsFilter';
import ScreeningsAgesFilter from './Screenings/AgesFilter';
import ScreeningsIndicatorsFilter from './Screenings/IndicatorsFilter';

import PSAgencyFilter from './PublicSafety/AgencyFilter';
import PSOffensesFilter from './PublicSafety/OffensesFilter';
import PSOffensesLAFilter from './PublicSafety/OffensesLAFilter';
import PSLocationFilter from './PublicSafety/LocationFilter';
import PSLocationLAFilter from './PublicSafety/LocationLAFilter';
import PSTimeFilter from './PublicSafety/TimeFilter';


// import FederalLocationsFilter from './FederalCases/LocationFilter';
import NationalFCLoFilter from './FederalCases/NationalFCLoFilter'
import FederalLocationsTypeFilter from './FederalCases/LocationTypeFilter';
import FederalTypesFilter from './FederalCases/TraffickingType';
// import FederalLocationsLAFilter from './FederalCases/LocationLAFilter';

import StatePolicyLocationsFilter from './StatePolicy/Location';
import StatePolicyTimeFilter from './StatePolicy/Time';
import StatePolicyPolicyFilter from './StatePolicy/Policy';

import TDCJOffensesFilter from './TDCJ/OffensesFilter';
import TDCJLocationFilter from './TDCJ/LocationFilter';

import DemandAdSitesFilter from './Demand/AdSitesFilter';

import NewsLocationFilter from './News/LocationFilter';

import HotlineSituationsFilter from './Hotline/SituationsFilter';
import HotlineTimeFilter from './Hotline/TimeFilter';
import HotlineLocationFilter from './Hotline/LocationFilter'

import ImbLocationFilter from './IMB/LocationFilter';
import ImbMapLocationFilter from './IMB/ImbMapLocationFilter';
import ImbLocationLAFilter from './IMB/LocationLAFilter';
import ImbAnalysisFilter from './IMB/AnalysisFilter';
import ImbYearFilter from './IMB/Year';

import TraffickingLocationTypeFilter from './TraffickingLocations/LocationTypeFilter'
import TraffickingLocationFilter from './TraffickingLocations/LocationFilter';
import TraffickingTimeFilter from './TraffickingLocations/TimeFilter';
import TraffickingAgeFilter from './TraffickingLocations/AgeFilter';
import TraffickingOrganizationFilter from './TraffickingLocations/OrganizationFilter';
import TraffickingTrafTypeFilter from './TraffickingLocations/TrafTypeFilter';
import VictimStatusFilter from './TraffickingLocations/VictimStatusFilter';


//import { data } from '../Charts/Screenings/DemographicData';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    }
}));


export default function Filters(props) {
    const classes = useStyles();
    const [filter, setFilter] = React.useState([]);
    const { stateLocation } = useAppContext();
    const [showImbMapYearFilter, setShowImbMapYearFilter] = useState(true)

    const handleFilterCallback = (item) => {
        props.filterCallback(item)
    }

    useEffect(() => {
        setFilter(props.filter)

        for (let i = 0; i < props.selectedFilters.length; i++) {
            if (props.selectedFilters[i].name === 'Location ' && props.selectedFilters[i].filter === 'Businesses of Interest' && props.selectedFilters[i].value[0] === 'Zip Code') {
                setShowImbMapYearFilter(false)
            }
        }
    }, [props.filter, props.secondaryNav, props.selectedFilters])


    return (
        <div className={`${classes.container} ${props.styles?.container?.className || ''}`}>
            {/* Services Filters */}
            { filter === 'Time' && props.data === 'Services' && stateLocation === 'TX' ?
                <ServicesTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Time ' && props.data === 'Services' && stateLocation === 'TX' ?
                <ServicesYearFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Location' && props.data === 'Services' && stateLocation === 'TX' ?
                <ServicesLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Location ' && props.data === 'Services' && stateLocation === 'TX' ?
                <ServicesLocationTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}

            {/* LA Filters */}
            { filter === 'Ages' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLAAgeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Organizations' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLAOrganizationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Trafficking Types' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLATrafTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Provider Location ' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLALocationMapFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Location of Origin' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLALocationFilter filterName={filter} filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Provider Location' && props.data === 'Services' && stateLocation === 'LA' ?
                <ServicesLALocationFilter filterName={filter} filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { filter === 'Time' && props.data === 'Services' && stateLocation === 'LA' ?
            <ServicesLATimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Victim Status' && props.data === 'Services' && stateLocation === 'LA' ?
            <VictimStatusTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            {/* Care Coordination Filters */}
            { filter === 'Time' && props.data === 'Care Coordination' ?
                <CCTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { stateLocation === 'TX' && filter === 'Location' && props.data === 'Care Coordination'?
                <CCLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
                : null}

            { stateLocation === 'LA' && filter === 'Location' && props.data === 'Care Coordination' ?
                <CCLocationLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            { stateLocation === 'LA' && filter === 'Trafficking Types' && props.data === 'Care Coordination' ?
                <TraffTypeLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            { stateLocation === 'LA' && filter === 'Individuals Served' && props.data === 'Care Coordination' ?
                <IndividualsServedLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}

            {/* Screenings */}
            { filter === 'Time' && props.data === 'Screenings'?
                <ScreeningsTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Location' && props.data === 'Screenings'?
                <ScreeningsLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Organizations' && props.data === 'Screenings'?
                <ScreeningsOrgFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Screenings' && props.data === 'Screenings'?
                <ScreeningsScreeningsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Ages' && props.data === 'Screenings'?
                <ScreeningsAgesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Indicators' && props.data === 'Screenings'?
                <ScreeningsIndicatorsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Location' && props.data === 'Law Enforcement' && stateLocation === 'LA'?
                <PSLocationLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Location' && props.data === 'Law Enforcement' && stateLocation === 'TX'?
                <PSLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Arrest Year' && props.data === 'Law Enforcement' ?
                <PSTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} stateLocation = {stateLocation}/>
            : null}
            { filter === 'Agency Type' && props.data === 'Law Enforcement'?
                <PSAgencyFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Offenses' && props.data === 'Law Enforcement' && stateLocation === 'TX'?
                <PSOffensesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Offenses' && props.data === 'Law Enforcement' && stateLocation === 'LA'?
                <PSOffensesLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
            : null}

            {/* Federal Cases */}
            { filter === 'Year Case Filed' && props.data === 'Federal Cases' &&
                <TimeYearFilter
                page='Federal Cases'
                filterName={filter}
                options={['All Years', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000']}
                filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
               }
            { filter === 'Location ' && props.data === 'Federal Cases' ?
                <FederalLocationsTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            {/* { filter === 'Location' && props.data === 'Federal Cases' && stateLocation === 'TX' ?
                <FederalLocationsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
                : null} */}
            { filter === 'Location' && props.data === 'Federal Cases' ?
                <NationalFCLoFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose} />
                : null}
            {/* { filter === 'Location' && props.data === 'Federal Cases' && stateLocation === 'LA'?
                <FederalLocationsLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>

            : null} */}
            { filter === 'Location Type' && props.data === 'Federal Cases'?
                <FederalLocationsTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Trafficking Type' && props.data === 'Federal Cases'?
                <FederalTypesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}

            {/* State Policy */}
            { filter === 'Location' && props.data === 'State Policy'?
                <StatePolicyLocationsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Year' && props.data === 'State Policy'?
                <StatePolicyTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Policy' && props.data === 'State Policy'?
                <StatePolicyPolicyFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}

            { filter === 'Location' && props.data === 'Texas Department of Criminal Justice'?
                <TDCJLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Offenses' && props.data === 'Texas Department of Criminal Justice'?
                <TDCJOffensesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Ad Sites' && props.data === 'Demand'?
                <DemandAdSitesFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
             { filter === 'Location' && props.data === 'News'?
                <NewsLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            {/* Hotline */}
            { filter === 'Time' && props.data === 'Hotline'?
                <HotlineTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Situations' && props.data === 'Hotline'?
                <HotlineSituationsFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Location' && props.data === 'Hotline'?
            <HotlineLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
                : null}
            {/* chart */}
            { filter === 'Location' && props.data === 'Businesses of Interest' && stateLocation === 'TX'?
                <ImbLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
                : null}
            {/* chart */}
            { filter === 'Location' && props.data === 'Businesses of Interest' && stateLocation === 'LA' ?
                <ImbLocationLAFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
                : null}
            {/* map */}
            { filter === 'Location ' && props.data === 'Businesses of Interest' ?
                <ImbMapLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters}  handleClose={props.handleClose} />
                : null}
            { filter === 'IMB Analysis' && props.data === 'Businesses of Interest'?
                <ImbAnalysisFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Year' && props.data === 'Businesses of Interest' && showImbMapYearFilter ?
                <ImbYearFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Location' && props.data === 'Locations'?
                <TraffickingLocationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Location Type' && props.data === 'Locations'?
                <TraffickingLocationTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Time' && props.data === 'Locations'?
                <TraffickingTimeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Ages' && props.data === 'Locations'?
                <TraffickingAgeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Organizations' && props.data === 'Locations'?
                <TraffickingOrganizationFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Trafficking Types' && props.data === 'Locations'?
                <TraffickingTrafTypeFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
            { filter === 'Victim Status' && props.data === 'Locations' ?
            <VictimStatusFilter filterCallback={handleFilterCallback} selectedFilters={props.selectedFilters} handleClose={props.handleClose}/>
            : null}
        </div>
    )
}