/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { CSVLink } from "react-csv";
import { useAppContext } from "../../Lib/UserContext";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { chartData } from './ChartData';
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import Services from './Charts/Services/Services';
import CareCoordination from './Charts/CareCoordination/CareCoordination';
import Screenings from './Charts/Screenings/Screenings';
import TrafLocations from './Charts/TraffickingLocations/TraffickingLocations';
import PublicSafety from './Charts/PublicSafety/PublicSafety';
import FederalCases from './Charts/FederalCases/FederalCases';
import StatePolicy from './Charts/StatePolicy/StatePolicy';
import TDCJ from './Charts/TDCJ/TDCJ';
import Demand from './Charts/Demand/Demand';
import News from './Charts/News/News';
import Hotline from './Charts/Hotline/Hotline';
import CommunityRisk from './Charts/Risk/CommunityRisk';
import TXCareCoordinationFormStepper from "./Charts/CareCoordination/TXCareCoordinationFormStepper";
import LACareCoordinationFormStepper from "./Charts/CareCoordination/LACareCoordinationFormStepper";
import BusinessesOfInterest from "./Charts/IMB/IMB";
//import ScrollSpy from 'react-scrollspy-navigation';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    marginTop: '67px',
    marginLeft: '72px',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerIcons: {
    width: '2em'
  },
  drawerOpen: {
    marginTop: '67px',
    marginLeft: '72px',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden'
  },
  drawerClose: {
    marginTop: '67px',
    marginLeft: '72px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  activeIcon: {
    position: 'absolute',
    left: 0,
    top: '15px'
  },
  content: {
    marginTop: '34px',
    width: '100%',
    marginLeft: '-20px',
    marginRight: '40px',
    marginBottom: '50px',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  drawerDotsActive: {
    width: '15px',
    height: '15px',
    marginLeft: '5px',
    color: '#28ADE3'
  },
  drawerDotsInactive: {
    width: '15px',
    height: '15px',
    marginLeft: '5px',
    color: 'black',
    opacity: '0.5'
  }
}));

export default function Chart(props) {
  const classes = useStyles();
  const { role, stateLocation } = useAppContext();
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = React.useState(true);
  const [navigation, setNavigation] = React.useState("");
  const [listCharts, setListCharts] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [graph, setGraph] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [name, nameGraph] = React.useState("");
  const [ccFormOpen, setCCFormOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  //const [activeObserver, setActiveObserver] = React.useState(true);
  // Handle CC Form
  const handleOpenCCForm = () => {
    setCCFormOpen(true);
  }

  const ccCallback = () => {
    setCCFormOpen(false);
    // filterCallback(filterConfig);
  };

  // Add to Reports
  const addToReportPage = (data, chartLine, chartLineTwo, activeCategory) => {
    props.addToReportPage(data, navigation, chartLine, chartLineTwo, activeCategory);
   }

  // Opening and Closing Drawer
  // const handleDrawerOpen = () => {
  //   setOpen(!open);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleCategoryClick = (e, item) => {
    setCategory(item.name)
    setGraph(item.values[0])
  }

  const handleGraphClick = (e, item) => {
   // setActiveObserver(false);
    setGraph(item)
    var id = category + " " + item;
    // document.getElementById(id).scrollIntoView({behavior: "auto", block: "start", inline: "nearest"});

     // // setActiveObserver(true);

    var element = document.getElementById(id);
    var headerOffset = props.bannerOpen ? 118 : 64;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
    });
  }

  // eslint-disable-next-line no-unused-vars
  const [activeId, setActiveId] = useState();
  const headingElementsRef = useRef({});

  useEffect(() => {
    var filteredArray = chartData.filter(x => x.name === props.charts && x.state_location === stateLocation)
    if (filteredArray[0]) {
      // console.log('props.charts', props.charts)
      setNavigation(props.charts);
      setListCharts(filteredArray[0].charts);

      if (!graph) {
        setCategory(filteredArray[0].charts[0].name)
        setGraph(filteredArray[0].charts[0].values[0])
      } else {
        setCategory(category)
        setGraph(graph)
      }
    }
    setLoading(props.chartLoading)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.charts, props.chartLoading, stateLocation])

  useEffect(() => {
    const callback = (headings) => {
   // Might be the lazy loading slowing the page down?
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });
      if (visibleHeadings.length > 0) {
        const getIndexFromId = (id) =>
          headingElements.findIndex((heading) => heading.id === id);
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );
        // Select the last visible section on the page to be active
        let lastIndex = sortedVisibleHeadings.length - 1
        let categoryName = sortedVisibleHeadings[lastIndex].target.className.split("single-card")[1];
        // Remove/replace the first instance of the categoryName in the id to get the graphName
        let graphName = sortedVisibleHeadings[lastIndex].target.id.replace(categoryName.trim(), '') // .split(categoryName.trim())[1];
        setCategory(categoryName.trim())
        setGraph(graphName.trim())
      }
    };

    var observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '-64px 0px 0px 0px',
      threshold: 0.60, // 1.0 = 100% of target is in viewport
      });

    const headingElements = Array.from(document.querySelectorAll(".single-card "));
      if (headingElements){
        headingElements.forEach((element) => observer.observe(element));
      }
    return () => observer.disconnect();
  }, [listCharts])



  return (
   <div className={classes.root}>
      <Drawer id = 'chart'
        variant="permanent"
        className={`${clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })} chartDrawer`}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })
        }}
      >
        {/* <div className={classes.toolbar}>
            {open ?
               <IconButton onClick={handleDrawerClose}>
                    { <ChevronLeftIcon /> }
                </IconButton>
            :
              <IconButton onClick={handleDrawerOpen}>
                    { <ChevronRightIcon /> }
                </IconButton>
            }
            <ListItem button onClick={handleDrawerOpen}>
              <ListItemText primary={"Hide outline"} className="hide-outline"/>
            </ListItem>
        </div> */}
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
        <List className="primary-nav-list-item" activeid={activeId}>
            {navigation === 'Care Coordination' ? (
              <div>
                <a
                  href={stateLocation === 'TX' ? "https://lighthouse-pdf-images.s3.us-west-2.amazonaws.com/CC+Custom+Performance+Measures_V26_21-06-18_Glossary.pdf" : "https://drive.google.com/file/d/1kMg9SMAjdsCTXljowKl-ucKExc0W8rtn/view?usp=sharing"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{textDecoration: 'none'}}
                >
                  <ListItem button style={{padding: '0px'}}>
                      <div style={{display: 'flex', alignItems: 'center', color: '#28ADE3'}}>
                          <InfoIcon style={{marginRight: '10px'}}/>
                          <ListItemText primary={"CC Glossary"} />
                      </div>
                  </ListItem>
                </a>
                {role === 'Admin' &&
                  <ListItem button onClick={handleOpenCCForm} style={{ padding: '0px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', color: '#28ADE3' }}>
                      <AddIcon style={{ marginRight: '10px' }} />
                      <ListItemText primary={"Add Performance Measures"} />
                    </div>
                  </ListItem>
                }
                {props.care.allData ? (
                  <CSVLink
                    data={props.care.allData}
                    target="_blank"
                    filename={`care-coordination-data.csv`}
                    style={{textDecoration: 'none'}}
                  >
                    <ListItem button style={{padding: '0px'}}>
                      <div style={{display: 'flex', alignItems: 'center', color: '#28ADE3'}}>
                          <GetAppIcon style={{marginRight: '10px'}}/>
                          <ListItemText primary={"Download CSV"}/>
                      </div>
                    </ListItem>
                  </CSVLink>
                 ): null}
              </div>
            ) : null}
          {listCharts.map((navItem, key) => {
            return (
              <div
                key={key}
                className={`${props.charts === 'Federal Cases' && 'fontMulish fontWeight500'}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                {category === navItem.name ?
                <div>
                  <ListItem button onClick={e => handleCategoryClick(e, navItem)}>
                      <div style={{display: 'flex'}}>
                          {/* <img src={icon} alt="active tab" className={classes.activeIcon}/> */}
                          <ListItemText primary={navItem.name} style={{whiteSpace: 'normal'}} />
                      </div>
                  </ListItem>
                    {navItem.values.map((item, key) => {
                      if (navItem.name === 'Emergency Shelter' && item === 'Average Length of Stay by Program'){
                        if (role === 'Admin' || role === 'OOG') {
                          return (
                            <div key={key}>
                              {graph === item ?
                                <ListItem button onClick={e => handleGraphClick(e, item)} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                                    <ListItemIcon><FiberManualRecordIcon className={classes.drawerDotsActive} /></ListItemIcon>
                                    <ListItemText primary={item} style={{whiteSpace: 'normal'}} className="graph-text"/>
                                </ListItem>
                              :
                                <ListItem button onClick={e => handleGraphClick(e, item)} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                                  <ListItemIcon><FiberManualRecordIcon className={classes.drawerDotsInactive} /></ListItemIcon>
                                  <ListItemText style={{whiteSpace: 'normal', opacity: '0.5'}} primary={item} className="graph-text"/>
                              </ListItem>
                            }
                            </div>
                          )
                        } else {
                          console.log("No access")
                        }
                      } else if (item === "Governor's Office Funding") {
                        if (role === 'Admin' || role === 'OOG') {
                          return (
                            <div key={key}>
                              {graph === item ?
                                <ListItem button onClick={e => handleGraphClick(e, item)} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                                    <ListItemIcon><FiberManualRecordIcon className={classes.drawerDotsActive} /></ListItemIcon>
                                    <ListItemText primary={item} style={{whiteSpace: 'normal'}} className="graph-text"/>
                                </ListItem>
                              :
                                <ListItem button onClick={e => handleGraphClick(e, item)} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                                  <ListItemIcon><FiberManualRecordIcon className={classes.drawerDotsInactive} /></ListItemIcon>
                                  <ListItemText style={{whiteSpace: 'normal', opacity: '0.5'}} primary={item} className="graph-text"/>
                              </ListItem>
                            }
                            </div>
                          )
                        } else {
                          console.log("No access")
                        }
                      } else {
                        return (
                          <div key={key}>
                            {graph === item ?
                              <ListItem button onClick={e => handleGraphClick(e, item)} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                                  <ListItemIcon><FiberManualRecordIcon className={classes.drawerDotsActive} /></ListItemIcon>
                                  <ListItemText primary={item} style={{whiteSpace: 'normal'}} className="graph-text"/>
                              </ListItem>
                            :
                              <ListItem button onClick={e => handleGraphClick(e, item)} style={{paddingTop: '0px', paddingBottom: '0px'}}>
                                <ListItemIcon><FiberManualRecordIcon className={classes.drawerDotsInactive} /></ListItemIcon>
                                <ListItemText style={{whiteSpace: 'normal', opacity: '0.5'}} primary={item} className="graph-text"/>
                            </ListItem>
                          }
                          </div>
                        )
                      }

                    })}
                </div>
                :
                <ListItem button onClick={e => handleCategoryClick(e, navItem)}>
                      <div style={{display: 'flex'}}>
                          <ListItemText primary={navItem.name} style={{opacity: '0.5', whiteSpace: 'normal'}} />
                      </div>
                  </ListItem>
                }
            </div>
          )})}
        </List>
        </div>
      </Drawer>
      <main className={classes.content}>
          {navigation === "Services" ?
          <Services chart={category + " " + graph} name={name} data={props.services} addToReportPage={addToReportPage} />
          : null}
          {navigation === "Care Coordination" ?
          <CareCoordination chart={category + " " + graph} name={name} data={props.care} addToReportPage={addToReportPage} />
          : null}
          {navigation === "Screenings" ?
            <Screenings chart={category + " " + graph} name={name} data={props.screenings} addToReportPage={addToReportPage}/>
          : null}
          {navigation === "Locations" ?
            <TrafLocations chart={category + " " + graph} name={name} data={props.trafLocations} addToReportPage={addToReportPage}/>
          : null}
          {navigation === "Law Enforcement" ? 
          <PublicSafety chart={category + " " + graph} name={name} data={props.publicSafety} addToReportPage={addToReportPage} stateLocation={stateLocation} />
          : null}
           {navigation === "Federal Cases" ?
          <FederalCases chart={category + " " + graph} name={name} data={props.federalCases} addToReportPage={addToReportPage} />
          : null}
          {navigation === "State Policy" ? 
          <StatePolicy chart={category + " " + graph} name={name} data={props.statePolicy} addToReportPage={addToReportPage} handleStateClick={props.handleStateClick} statePolicyStateSelection={props.statePolicyStateSelection}/>
          : null}
           {navigation === "Texas Department of Criminal Justice" ? 
            <TDCJ chart={category + " " + graph} name={name} data={props.tdcj} addToReportPage={addToReportPage}/>
          : null}
          {navigation === "Demand" ?
            <Demand chart={category + " " + graph} name={name} data={props.demand} addToReportPage={addToReportPage}/>
          : null}
          {navigation === "News" ?
            <News chart={category + " " + graph} name={name} data={props.news} addToReportPage={addToReportPage}/>
          : null}
          {navigation === "Hotline" ?
            <Hotline chart={category + " " + graph} name={name} data={props.hotline} addToReportPage={addToReportPage}/>
            : null}
          {navigation === "Businesses of Interest" ?
          <BusinessesOfInterest chart={category + " " + graph} name={name} data={props.imb} addToReportPage={addToReportPage} />
          : null}
          {navigation === "Community Risk" ?
            <CommunityRisk chart={category + " " + graph} name={name} data={props.community} addToReportPage={addToReportPage}/>
          : null}
      </main>

      {stateLocation === 'TX' ?
        <TXCareCoordinationFormStepper open={ccFormOpen} callBack={ccCallback} />
        :
        <LACareCoordinationFormStepper open={ccFormOpen} callBack={ccCallback} />
      }

      <Dialog
        open={loading}
        onClose={props.handleChartLoadingClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2>Updating Data...</h2>
          <CircularProgress />
        </DialogContent>
      </Dialog>
     </div>
  );
}